import React, { useState } from 'react';
import { Card, ListGroup, ListGroupItem, Button, Collapse } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import stores from "../../store";

const InfoCard = ({ data, selectedQuestionnaireMain, completeSearch }) => {

    console.log("search complete", completeSearch)

    const [open, setOpen] = useState(false);
    const { useSharedStore } = stores;

    const setSelectedQuestionnaireState = useSharedStore(
        (state) => state.setSelectedQuestionnaire
    );

    const buttonStyle = {
        borderRadius: "0px",
        height: "40px",
        marginBottom: "10px",
        marginRight: "10px",
        borderColor: "#f8f9fa",
    };

    const handleNavigate = () => {
        setSelectedQuestionnaireState(data);
        console.log("selectedQuestionnaireMain", selectedQuestionnaireMain);
        // history.push(`/elements?target=${selectedQuestionnaireMain}`);
        window.open(`${data.linkToForm}`, '_blank');
    };

    return (
        <>
            <Card style={{ width: '100%', marginTop: '20px', borderRadius: '0px' }}>
                <Card.Body>
                    <Button variant="primary" onClick={handleNavigate} style={{ marginBottom: '10px', height: "40px", borderRadius: "0px" }}>
                        Preview Questionnaire
                    </Button>

                    <Card.Title>{data.title}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{data.country}</Card.Subtitle>
                    <Card.Text>{data.description}</Card.Text>
                    <Collapse in={open}>
                        <div>
                            <ListGroup className="list-group-flush">
                                <ListGroupItem><strong>Creator:</strong> {data.creator}</ListGroupItem>
                                <ListGroupItem><strong>Institution:</strong> {data.institution}</ListGroupItem>
                                <ListGroupItem><strong>Version:</strong> {data.version || 'N/A'}</ListGroupItem>
                                <ListGroupItem><strong>Date Issued:</strong> {data.dateIssue || 'N/A'}</ListGroupItem>
                                <ListGroupItem><strong>#Elements:</strong> {data.idsrElements?.length || 'N/A'}</ListGroupItem>
                            </ListGroup>
                        </div>
                    </Collapse>

                    <Button
                        variant="secondary"
                        className='text-gray-900'
                        onClick={() => setOpen(!open)}
                        style={buttonStyle}
                    >
                        {open ? 'Collapse Details' : 'Expand Details'}
                    </Button>
                </Card.Body>
            </Card>
        </>
    );
};

export default InfoCard;