import React from "react";
import DataTable from "react-data-table-component";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  head: {
    style: {
      fontWeight: "bold", // Font weight for header
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
      fontWeight: "semibold", // make the headers bold
      fontSize: "12pt",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
    },
  },
  firstCell: {
    style: {
      width: "50px", // adjust the width of the first cell
      paddingLeft: "4px", // adjust the padding for the first cell
      paddingRight: "4px",
    },
  },
};

function Table({ data, columns, title }) {
  return (
    <DataTable
      // title={title}
      columns={columns}
      data={data}
      pagination
      responsive
      customStyles={customStyles}
      highlightOnHover
      pointerOnHover
    />
  );
}

export default Table;
