import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Button,
  Form,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import SimulationScreenTable from "../../components/SimulationScreenTable";
import axios from "axios";
import { containerStyle, buttonStyle, resetButtonStyle, styles, commonCodebookButtonStyles, colorColumnStyle } from "./styles"
import { fetchIdsrElements } from "../../helpers/SimulationScreen/fetchIdsrElements";
import InitialSetup from "../../components/Standardization/InialSearch";
import SearchInit from "../../components/Standardization/SearchInit";
import WhileSearch from "../../components/Standardization/WhileSearch";
import stores from "../../store";
import {
  Page,
  Text,
  View,
  Document,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { toast } from "react-toastify";
import InfoCard from "./InfoCard";
import Instructions from "./Instructions";

const {
  runSimulation,
} = require("../../helpers/SimulationScreen/runSimulation");

const SimulationScreen = () => {

  const { useFormStore, useSharedStore } = stores;

  const {
    formMeta,
    formValues,
    hasUserUploaded,
  } = useFormStore();
  const {
    selectedQuestionnaire,
    questionnaireMetaInfo } = useSharedStore();

  const [isLoading, setIsLoading] = useState(false);
  const [reRunSearchBool, setReRunSearchBool] = useState(false);
  const [searchBegan, setSearchBegan] = useState(false);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [questionnaireMeta, setQuestionnaireMeta] = useState("");
  const [questionnaireId, setQuestionnaireId] = useState("");
  const [showQuestDetails, setShowQuestDetails] = useState(false);
  const [idsrElements, setIdsrElements] = useState([]);
  const [newIdsrElements, setNewIdsrElements] = useState([]);
  const [editedElements, setEditedElements] = useState([]);
  const [showSimulationTable, setShowSimulationTable] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [searchSuccess, setSearchSuccess] = useState(false);
  const [selectedQuestionnaireMain, setselectedQuestionnaireMain] = useState("");
  const [currentSearch, setCurrentSearch] = useState("Warming up...");
  const [selectedItems, setSelectedItems] = useState([]);
  const [completeSearch, setCompleteSearch] = useState(false);
  const [editItemIndex, setEditItemIndex] = useState("");
  const [editItemDescription, setEditItemDescription] = useState("");
  const [filterValue, setFilterValue] = useState("GREEN");
  const [currentEditItem, setCurrentEditItem] = useState({});
  const [exportCSVData, setExportCSVData] = useState([]);
  const [codeBookFormMeta, setCodeBookFormMeta] = useState({});
  const [lookUpMap, setLookUpMap] = useState({});
  const [currentQuestionnaireTitle, setCurrentQuestionnaireTitle] = useState("Select a Questionnaire");
  const [generatePdf, setGeneratePdf] = useState(false);
  const [elementCount, setElementCount] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://us-central1-questmap-mubas.cloudfunctions.net/getSimulationScreenIdsrQListing"
      );

      setQuestionnaires(response.data); // If successful, return the data
    } catch (error) {
      console.log(error);
    }
  };

  const findMapByIdsrQPID = (arr, id) => {
    // console.log(arr, id);
    return arr.find((map) => map.idsrQPID === parseInt(id));
  };

  const handleFetchData = async () => {
   setShowQuestDetails(true)
  };

  const handleQuestionnareSelect = (event) => {
    setShowQuestDetails(false)

    // setIsStandardize(false)
    setselectedQuestionnaireMain('')
    setShowSimulationTable(false)
    setSelectedItems([]);
    const Id = event.target.value;
    const questionnaireMeta = findMapByIdsrQPID(
      questionnaires,
      Id
    );
    console.log("here is meta", questionnaireMeta);
    setQuestionnaireMeta(questionnaireMeta);
    setQuestionnaireId(Id);

    setselectedQuestionnaireMain(Id);
  };
  async function extractFirstElements(inputArray) {
    const resultArray = [];

    // Iterate through the array of maps
    inputArray.forEach((map) => {
      // Check if the map has a 'child' key containing an array
      // and if the array is not empty
      if (
        map.hasOwnProperty("child") &&
        Array.isArray(map.child) &&
        map.child.length > 0
      ) {
        // Simply add the first child to the result array
        resultArray.push(map.child[0]);
      }
    });

    return resultArray;
  }

  useEffect(() => {
    if (hasUserUploaded && formValues.length > 0) {
      const handleSearch = async () => {
        // console.log("here are values", formValues);
        setselectedQuestionnaireMain(formMeta.title);
        setSelectedItems([]);
        setReRunSearchBool(false);
        setSearchBegan(true);
        setCompleteSearch(false);
        setIsLoading(true);
        setCurrentSearch("Warming up...");
        const simulationResult = await runSimulation(
          currentSearch,
          setCurrentSearch,
          formValues,
          reRunSearchBool
        );
        if (simulationResult.length > 0) {
          // console.log('here is simulation result',simulationResult);
          const extractedFirstElements = await extractFirstElements(
            simulationResult
          );
          setSelectedItems(extractedFirstElements);
          // console.log('Extracted first elemeents', extractFirstElements);
          setElementCount(extractedFirstElements.length);
          setIdsrElements(simulationResult);
          setIsLoading(false);
          setCompleteSearch(true);
          await createLookupData(simulationResult);
          setShowSimulationTable(true)

        } else {
          toast.error("Something went wrong. Please try again later.");
          setIsLoading(false);
        }
      };

      handleSearch();
    } else if (selectedQuestionnaire != null && !hasUserUploaded && questionnaireMetaInfo[0] != null) {

      // console.log("Here is selected Questionnaire", selectedQuestionnaire);
      const handleSearch = async () => {
        setReRunSearchBool(false);
        setSearchBegan(true);
        setSelectedItems([]);
        setselectedQuestionnaireMain(questionnaireMetaInfo[0].idsrFieldID);
        setCurrentQuestionnaireTitle(questionnaireMetaInfo[0].title);
        const questionnaireId = selectedQuestionnaire;
        const questionnaireMeta = findMapByIdsrQPID(
          questionnaireMetaInfo,
          questionnaireId
        );
        setQuestionnaireMeta(questionnaireMeta);
        setCompleteSearch(false);
        setIsLoading(true);
        const data = await fetchIdsrElements(selectedQuestionnaire);
        setCurrentSearch("Warming up...");
        const simulationResult = await runSimulation(
          currentSearch,
          setCurrentSearch,
          data,
          reRunSearchBool
        );

        if (simulationResult.length > 0) {

          // console.log(simulationResult);
          const extractedFirstElements = await extractFirstElements(
            simulationResult
          );
          setSelectedItems(extractedFirstElements);
          setElementCount(extractedFirstElements.length);
          setIdsrElements(simulationResult);
          setIsLoading(false);
          setCompleteSearch(true);
          setCodeBookFormMeta(questionnaireMeta);
          await createLookupData(simulationResult);
          setShowSimulationTable(true)

        } else {
          toast.error("Something went wrong. Please try again later.");
          setIsLoading(false);
        }
      };

      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function createLookupData(parentArray) {
    let lookup = {};

    parentArray.forEach((parent) => {
      parent.child.forEach((child) => {
        lookup[child.index] = {
          parentData: parent,
          childData: child,
        };
      });
    });

    // console.log("here is lookup", lookup);
    setLookUpMap(lookup);
  }

  const handleSearch = async () => {
    setSelectedItems([]);
    setReRunSearchBool(false);
    setSearchBegan(true);
    setCompleteSearch(false);
    setIsLoading(true);
    const data = await fetchIdsrElements(selectedQuestionnaireMain);
    setCurrentSearch("Warming up...");
    const simulationResult = await runSimulation(
      currentSearch,
      setCurrentSearch,
      data,
      reRunSearchBool
    );
    if (simulationResult.length > 0) {
      // console.log("here is simulation result", simulationResult);
      const extractedFirstElements = await extractFirstElements(simulationResult);
      setSelectedItems(extractedFirstElements);
      setElementCount(extractedFirstElements.length);
      setIdsrElements(simulationResult);
      setIsLoading(false);
      setCompleteSearch(true);
      setCodeBookFormMeta(questionnaireMeta);
      await createLookupData(simulationResult);
      setShowSimulationTable(true)

    } else {
      toast.error("Something went wrong. Please try again later.");
      setIsLoading(false);
    }

  };

  const handleRerun = () => {
    async function reRun() {
      async function mergeArraysByIndexField(array1, array2, indexField) {
        // Create a map to store index field values and their corresponding objects from array2
        const indexMap = new Map();

        // Populate the map with objects from array2
        for (const obj of array2) {
          const key = obj[indexField];
          indexMap.set(key, obj);
        }

        // Create a new array to store the merged objects
        const mergedArray = [];

        // Iterate through array1
        for (const obj of array1) {
          const key = obj[indexField];
          // If the object from array1 has the same index field value in array2, use the one from array2
          if (indexMap.has(key)) {
            // console.log("found it in merged array", indexMap.get(key));
            mergedArray.push(indexMap.get(key));
          } else {
            // If not found in array2, use the object from array1
            mergedArray.push(obj);
          }
        }

        return mergedArray;
      }
      setNewIdsrElements([]);
      setSearchBegan(true);
      setCompleteSearch(false);
      setIsLoading(true);
      setCurrentSearch("Warming up...");
      // console.log("Here is edited Elements before rerun", editedElements);
      const simulationResult = await runSimulation(
        currentSearch,
        setCurrentSearch,
        editedElements,
        true
      );
      // console.log("here is simulation rerun", simulationResult);
      setNewIdsrElements(simulationResult);
      const mergedArray = await mergeArraysByIndexField(
        idsrElements,
        newIdsrElements,
        "index"
      );

      // console.log("here is merrged array", mergedArray);
      const extractedFirstElements = await extractFirstElements(mergedArray);

      if (extractedFirstElements.length === 0) {
        setSearchSuccess(false)
        return
      }
      // console.log("here is extracted first elements", extractedFirstElements);
      setSelectedItems(extractedFirstElements);

      setElementCount(extractedFirstElements.length);
      setIdsrElements(mergedArray);
      setIsLoading(false);
      setCompleteSearch(true);
      setCodeBookFormMeta(questionnaireMeta);
      await createLookupData(mergedArray);
      setEditedElements([]);
    }

    reRun();
  };

  const handleReset = async () => {
    window.location.reload();
  };

  const handleFilterChange = (value) => {
    setFilterValue(value);
  };


  function renderGREEN(lookupMap) {
    return Object.values(lookupMap).some(data => data.parentData && data.parentData.color === "GREEN")
      ? <Text style={styles.matchHeaderGREEN}>
        The following are good matches to existing standardized fields
      </Text>
      : null;
  }
  function renderORANGE(lookupMap) {
    return Object.values(lookupMap).some(data => data.parentData && data.parentData.color === "ORANGE")
      ? <Text style={styles.matchHeaderORANGE}>
        The following are approximate matches to existing standardized fields
      </Text>
      : null;
  }
  function renderRED(lookupMap) {
    return Object.values(lookupMap).some(data => data.parentData && data.parentData.color === "RED")
      ? <Text style={styles.matchHeaderRED}>
        The following are distant matches to existing standardized fields
      </Text>
      : null;
  }

  const MyDocument = () => (
    <Document>
      <Page style={{ padding: 30 }} wrap>
        <Text style={styles.smallHeader}>
          {codeBookFormMeta?.title ?? formMeta.title}
        </Text>

        <View style={styles.table}>
          <Text style={styles.header}>
            {codeBookFormMeta?.title ?? formMeta.title}
          </Text>

          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>Title</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>
                {codeBookFormMeta?.title ?? formMeta.title}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow2}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>Creator</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>
                {codeBookFormMeta?.creater ?? formMeta.author}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow2}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>Copyright</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>
                Creative Commons Attribution-NonCommercial 4.0 International
                Public License
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>Institution</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>
                {codeBookFormMeta?.institution ?? formMeta.institution}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>Release Date</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>
                {codeBookFormMeta?.issueDate ?? formMeta.issueDate}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>Dataset Description</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>
                {codeBookFormMeta?.description ?? formMeta.description}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCell}>Variable Count</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell}>{selectedItems.length}</Text>
            </View>
          </View>
        </View>

        {renderGREEN(lookUpMap)}

        {selectedItems.map((item) => {
          const relatedData = lookUpMap[item.index];

          if (relatedData && relatedData.parentData.color === "GREEN") {

            return (
              <>
                <View style={styles.table2} key={item.index} wrap={false}>
                  <Text style={styles.header2}>
                    {relatedData.childData.metadata.idsrFieldID} -{" "}
                    {relatedData.childData.metadata.fieldDescr}
                  </Text>

                  {relatedData.childData.metadata.conceptId !== "null" ? (
                    <View style={styles.tableRow2}>
                      <View style={styles.tableCol1_2}>
                        <Text style={styles.tableCell2}>Concept Mapping</Text>
                      </View>
                      <View style={styles.tableCol2_2}>
                        <Text style={styles.tableCell}>
                          {" "}
                          ID:{relatedData.childData.metadata.conceptId} Concept:
                          {relatedData.childData.metadata.conceptMappingId} Idsr
                          Field:
                          {
                            relatedData.childData.metadata
                              .conceptMappingIdsrField
                          }{" "}
                        </Text>
                      </View>
                    </View>
                  ) : null}

                  <View style={styles.tableRow2}>
                    <View style={styles.tableCol1_2}>
                      <Text style={styles.tableCell2}>Type</Text>
                    </View>
                    <View style={styles.tableCol2_2}>
                      <Text style={styles.tableCell}>
                        {relatedData.childData.metadata.fieldType}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol1_2}>
                      <Text style={styles.tableCell2}>Description</Text>
                    </View>
                    <View style={styles.tableCol2_2}>
                      <Text style={styles.tableCell2}>
                        {relatedData.childData.metadata.fieldDescr}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol1_2}>
                      <Text style={styles.tableCell2}>Original Name</Text>
                    </View>
                    <View style={styles.tableCol2_2}>
                      <Text style={styles.tableCell2}>
                        {relatedData.parentData?.elemName ??
                          relatedData.parentData.elementName}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol1_2}>
                      <Text style={styles.tableCell2}>
                        Original Description
                      </Text>
                    </View>
                    <View style={styles.tableCol2_2}>
                      <Text style={styles.tableCell2}>
                        {relatedData.parentData.elemDescr}
                      </Text>
                    </View>
                  </View>
                </View>
              </>
            );
          } else {
            return null;
          }
        })}

        {renderORANGE(lookUpMap)}

        {selectedItems.map((item) => {
          const relatedData = lookUpMap[item.index];

          if (relatedData && relatedData.parentData.color === "ORANGE") {
            return (
              <>
                <View style={styles.table2} key={item.index} wrap={false}>
                  <Text style={styles.header2}>
                    {relatedData.childData.metadata.idsrFieldID} -{" "}
                    {relatedData.childData.metadata.fieldDescr}
                  </Text>

                  {relatedData.childData.metadata.conceptId !== "null" ? (
                    <View style={styles.tableRow2}>
                      <View style={styles.tableCol1_2}>
                        <Text style={styles.tableCell2}>Concept Mapping</Text>
                      </View>
                      <View style={styles.tableCol2_2}>
                        <Text style={styles.tableCell}>
                          {" "}
                          ID:{relatedData.childData.metadata.conceptId} Concept:
                          {relatedData.childData.metadata.conceptMappingId} Idsr
                          Field:
                          {
                            relatedData.childData.metadata
                              .conceptMappingIdsrField
                          }{" "}
                        </Text>
                      </View>
                    </View>
                  ) : null}

                  <View style={styles.tableRow2}>
                    <View style={styles.tableCol1_2}>
                      <Text style={styles.tableCell2}>Type</Text>
                    </View>
                    <View style={styles.tableCol2_2}>
                      <Text style={styles.tableCell}>
                        {relatedData.childData.metadata.fieldType}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol1_2}>
                      <Text style={styles.tableCell2}>Description</Text>
                    </View>
                    <View style={styles.tableCol2_2}>
                      <Text style={styles.tableCell2}>
                        {relatedData.childData.metadata.fieldDescr}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol1_2}>
                      <Text style={styles.tableCell2}>Original Name</Text>
                    </View>
                    <View style={styles.tableCol2_2}>
                      <Text style={styles.tableCell2}>
                        {relatedData.parentData?.elemName ??
                          relatedData.parentData.elementName}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol1_2}>
                      <Text style={styles.tableCell2}>
                        Original Description
                      </Text>
                    </View>
                    <View style={styles.tableCol2_2}>
                      <Text style={styles.tableCell2}>
                        {relatedData.parentData.elemDescr}
                      </Text>
                    </View>
                  </View>
                </View>
              </>
            );
          } else {
            return null;
          }
        })}

        {renderRED(lookUpMap)}

        {selectedItems.map((item) => {
          const relatedData = lookUpMap[item.index];

          if (relatedData && relatedData.parentData.color === "RED") {
            return (
              <>
                <View style={styles.table2} key={item.index} wrap={false}>
                  <Text style={styles.header2}>
                    {relatedData.childData.metadata.idsrFieldID} -{" "}
                    {relatedData.childData.metadata.fieldDescr}
                  </Text>

                  {relatedData.childData.metadata.conceptId !== "null" ? (
                    <View style={styles.tableRow2}>
                      <View style={styles.tableCol1_2}>
                        <Text style={styles.tableCell2}>Concept Mapping</Text>
                      </View>
                      <View style={styles.tableCol2_2}>
                        <Text style={styles.tableCell}>
                          {" "}
                          ID:{relatedData.childData.metadata.conceptId} Concept:
                          {relatedData.childData.metadata.conceptMappingId} Idsr
                          Field:
                          {
                            relatedData.childData.metadata
                              .conceptMappingIdsrField
                          }{" "}
                        </Text>
                      </View>
                    </View>
                  ) : null}

                  <View style={styles.tableRow2}>
                    <View style={styles.tableCol1_2}>
                      <Text style={styles.tableCell2}>Type</Text>
                    </View>
                    <View style={styles.tableCol2_2}>
                      <Text style={styles.tableCell}>
                        {relatedData.childData.metadata.fieldType}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol1_2}>
                      <Text style={styles.tableCell2}>Description</Text>
                    </View>
                    <View style={styles.tableCol2_2}>
                      <Text style={styles.tableCell2}>
                        {relatedData.childData.metadata.fieldDescr}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol1_2}>
                      <Text style={styles.tableCell2}>Original Name</Text>
                    </View>
                    <View style={styles.tableCol2_2}>
                      <Text style={styles.tableCell2}>
                        {relatedData.parentData?.elemName ??
                          relatedData.parentData.elementName}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.tableRow}>
                    <View style={styles.tableCol1_2}>
                      <Text style={styles.tableCell2}>
                        Original Description
                      </Text>
                    </View>
                    <View style={styles.tableCol2_2}>
                      <Text style={styles.tableCell2}>
                        {relatedData.parentData.elemDescr}
                      </Text>
                    </View>
                  </View>
                </View>
              </>
            );
          } else {
            return null;
          }
        })}
      </Page>
    </Document>
  );

  return (
    <div>
      {questionnaires.length === 0 ? (
        <InitialSetup />
      ) : (
        <Container style={containerStyle}>
          <MetaTags>
            <title>Standardization</title>
          </MetaTags>

          <Row className="container mt-4 mb-4">
            <div className="me-lg-5 d-flex align-items-center justify-content-center">
              <div className="job-list-header">
                <Form action="#">
                <Row>
                    <Col>
                      <p>Select a Questionnaire to Standardize</p>
                    </Col>
                </Row>
                  <Row className="g-0">

                    <div style={{ display: "inline-flex" }}>
                      
                      <Col lg={6} md={3} style={{ marginRight: "15px" }}>
                        <div className="filler-job-form">

                          <select
                            className="form-select"
                            value={selectedQuestionnaireMain}
                            styles={{ height: "40px" }}
                            onChange={handleQuestionnareSelect}
                          >
                            <option value="">
                              {currentQuestionnaireTitle}
                            </option>
                            {questionnaires.map((questionnaire) => (
                              <option

                                value={questionnaire.idsrQPID}
                              >
                                {questionnaire.title}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>                   
                        <Col lg={3} md={6}>
                          <Button onClick={handleFetchData} disabled={!selectedQuestionnaireMain} style={buttonStyle}>
                            View Questionnaire
                          </Button>
                        </Col>
                    </div>

                    {questionnaireMeta && showQuestDetails && (
                        <InfoCard data={questionnaireMeta} selectedQuestionnaireMain={selectedQuestionnaireMain} completeSearch={completeSearch} />
                    )}

                      <Instructions handleSearch={handleSearch} questionnaireMeta={questionnaireMeta}/>
                    
                       <div>
                          <div
                            style={{
                              marginTop: "15px",
                              display: "inline-flex",
                            }}
                          >
                            <button
                              style={{ marginRight: "20px", height: "40px" }}
                              onClick={handleRerun}
                              type="button"
                              className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                            >
                              {" "}
                              <i className="uil uil-refresh"></i> Update
                              Standardisation
                            </button>

                            <Button
                              onClick={handleReset}
                              style={resetButtonStyle}
                            >
                              <i className="uil uil-play"></i> Reset
                            </Button>

                            <div>
                              {!generatePdf ? (
                                <button style={commonCodebookButtonStyles} onClick={() => setGeneratePdf(true)}>
                                  Generate Codebook
                                </button>
                              ) : (
                                <PDFDownloadLink
                                  document={<MyDocument />}
                                  fileName="IdsrCodeBook.pdf"
                                  style={commonCodebookButtonStyles}
                                >
                                  {({ url, loading }) =>
                                    loading ? (
                                      "Generating Codebook..."
                                    ) : (
                                      <a

                                        style={{ textDecoration: 'none', color: 'black' }}

                                        href={url}
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        onClick={() => {

                                          setTimeout(() => setGeneratePdf(false), 100);
                                        }}
                                      >
                                        <i className="uil uil-save"></i> Download Codebook
                                      </a>
                                    )
                                  }
                                </PDFDownloadLink>
                              )}
                            </div>
                          </div>
                        <Col lg={12} md={10}>
                          {completeSearch === true ? (
                            <div>
                              <div
                                style={{
                                  display: "inline-flex",
                                  marginTop: "30px",
                                }}
                              >
                                <h6 style={{}} className="placeholder-text">
                                  The selected questionnaire has: {selectedItems.length}{" "}
                                  {selectedItems.length === 1
                                    ? "element"
                                    : "elements"}
                                </h6>{" "}
                                <h6
                                  style={{ marginLeft: "20px" }}
                                  className="placeholder-text"
                                >
                                  You have Edited: {editedElements.length}{" "}
                                  {editedElements.length === 1
                                    ? "description"
                                    : "descriptions"}
                                </h6>{" "}
                              </div>                             
                              <div
                                style={{
                                  display: "inline-flex",
                                  marginTop: "15px",
                                }}
                              >
                                <p>Show Exact Matches only: </p>
                                <div
                                  style={{
                                    ...colorColumnStyle,
                                    marginLeft: "5px",
                                    backgroundColor: "#10c23f",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleFilterChange("GREEN")}
                                ></div>
                                Show Approximate Matches only:{" "}
                                <div
                                  style={{
                                    ...colorColumnStyle,
                                    marginLeft: "5px",
                                    backgroundColor: "#f79e05",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleFilterChange("ORANGE")}
                                ></div>
                                Show Distant Matches only:{" "}
                                <div
                                  style={{
                                    ...colorColumnStyle,
                                    marginLeft: "5px",
                                    backgroundColor: "red",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleFilterChange("RED")}
                                ></div>
                              </div>

                              <div style={{ marginTop: "5px" }}></div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </div>
                    {/* )}    */}
                  </Row>
                </Form>
              </div>
            </div>
          </Row>

          <div>
            {selectedQuestionnaireMain !== "" ? (
              searchBegan === true ? (
                isLoading ? (
                  <WhileSearch currentSearch={currentSearch} />
                ) : (
                  <div>
                    {console.log("here is selected questionnaire main", selectedQuestionnaireMain)}
                      <SimulationScreenTable
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                        title={
                          filterValue === "GREEN"
                            ? "Showing: Exact Matches Only"
                            : filterValue === "ORANGE"
                              ? "Showing: Approximate Matches Only"
                              : filterValue === "RED"
                                ? "Showing: Distant Matches only"
                                : ""
                        }
                        data={idsrElements}
                        editItemIndex={editItemIndex}
                        setEditItemIndex={setEditItemIndex}
                        editItemDescription={editItemDescription}
                        setEditItemDescription={setEditItemDescription}
                        reRunSearchBool={reRunSearchBool}
                        filterValue={filterValue}
                        setIdsrElements={setIdsrElements}
                        editedElements={editedElements}
                        setEditedElements={setEditedElements}
                        currentEditItem={currentEditItem}
                        setCurrentEditItem={setCurrentEditItem}
                        exportCSVData={exportCSVData}
                        setExportCSVData={setExportCSVData}
                        questionnaireMeta={questionnaireMeta}
                      />
                  </div>
                )
              ) : (
                <SearchInit buttonText="Click standardize to proceed" />
              )
            ) : (
              <SearchInit buttonText="Select a Questionnaire to Begin" />
            )}
          </div>
        </Container>
      )}
    </div>
  );
};

export default SimulationScreen;
