import { create } from "zustand";

const useSharedStore = create((set) => ({
  selectedQuestionnaire: null,
  uploadedData: null,
  questionnaireMetaInfo: [],
  selectedContent: "instructions",


  setQuestionnaireMetaInfo: (valuesMap) => set((state) => ({
    questionnaireMetaInfo: [...state.questionnaireMetaInfo, valuesMap]
  })),

  setSelectedContent: (value) => set((state) => ({
    selectedContent: value
  })),

  setSelectedQuestionnaire: (questionnaire) =>
    set((state) => ({ selectedQuestionnaire: questionnaire })),

  setUploadedData: (data) => set((state) => ({ uploadedData: data })),
}));

const useFormStore = create((set) => ({
  formMeta: {
    title: "",
    description: "",
    country: "",
    author: "",
    type: "",
    institution: "",
    version: "",
    issueDate: "",
  },

  formValues: [],
  hasUserUploaded: false,

  toggleHasUserUploaded: () =>
    set((state) => ({
      hasUserUploaded: !state.hasUserUploaded
    })),

  // Function to set hasUserUploaded manually
  setHasUserUploaded: (value) =>
    set({ hasUserUploaded: value }),

  updateFormMeta: (meta) => set({ formMeta: { ...meta } }),

  addFormValues: (valuesMap) => set((state) => ({
    formValues: [...Object.values(valuesMap)]
  })),

  removeFormValues: (index) =>
    set((state) => ({ formValues: state.formValues.filter((_, i) => i !== index) })),
}));

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  useFormStore, useSharedStore
};
