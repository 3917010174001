import React, { useState } from "react";
import {
  Container,
  Button,
  ListGroup,
  ListGroupItem,
  Card,
  Accordion,
} from "react-bootstrap";
import MetaTags from "react-meta-tags";
import stores from "../../store";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const API_URL = "https://us-central1-questmap-mubas.cloudfunctions.net/insertQuestionnaires";

const ConfirmUploadPage = () => {
  const history = useHistory();
  const { useFormStore } = stores;

  const {
    formMeta,
    formValues,
     } = useFormStore();


  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isDisplayElements, setDisplayElements] = useState(true);

  // Define a mutation function
  const postFormData = async (formMeta) => {
    try {
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          qName: formMeta.title,
          qDescription: formMeta.description,
          dateIssue: formMeta.issueDate,
          author: formMeta.author,
          country: formMeta.country,
          institution: formMeta.institution,
          qNoVar: formMeta.stagings.length,
          stagings: formMeta.stagings
        }),
      });
      if (!response.ok) {
        throw new Error("Error posting data to the server");
      }
      return response.json();
    } catch (error) {
      throw new Error(`Error: ${error.message}`);
    }
  };

  // Use the useMutation hook to create a mutation function
  const mutation = useMutation(postFormData);

  const handleConfirmUpload = () => {
 
    setIsConfirmed(true);

    // Call the mutation function to send data to the server
    mutation.mutate(formMeta ,{
      onSuccess: (data) => {
        // The 'data' variable will contain the response data from the server
        console.log("Upload successful:", data);

        const param = data.qListing.qID;

        toast.success(`Uploaded successfully, moving to Standardization`);

        setTimeout(() => {
          history.push('/simulation');
        }, 6000);
      },
      onError: (error) => {
        // Handle the error here
        toast.error(`Error: ${error.message}`); // Display the error message
      },
    }); 
  };

  const elementContainerStyle = {
    backgroundColor: "#f0f0f0",
    padding: "20px",
    marginTop: "20px",
    borderRadius: "8px",
  };

  const containerStyle = {
    marginTop: "40px",
  };

  const formContainerStyle = {
    backgroundColor: "#f0f0f0",
    padding: "20px",
    borderRadius: "8px",
  };

  const buttonStyle = {
    margin: "8px",
    padding: "8px 16px",
    backgroundColor: "#007BFF",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  };

  const transparentButtonStyle = {
    ...buttonStyle,
    backgroundColor: "transparent",
    color: "#007BFF",
    border: "1px solid #007BFF",
  };

  const handleDisplayElement = () => {
    setDisplayElements(!isDisplayElements);
  };

  return (
    // <div className="elements-page" style={{ marginTop: "40px" }}>
    <div style={containerStyle}>
      <MetaTags>
        <title>Home | Confirm Upload</title>
      </MetaTags>

      <section className="py-5">
        {formMeta && (
          <Container>
            {/* <div className="confirmation-form bg-light p-4 rounded shadow"> */}
            <div style={formContainerStyle}>
              <h3 className="mb-4">Confirm Upload</h3>
              <div>
                <ListGroup>
                  <ListGroupItem>
                    <strong>Questionnaire:</strong> {formMeta.title}
                  </ListGroupItem>
                  <ListGroupItem>
                    <strong>Description:</strong> {formMeta.description}
                  </ListGroupItem>
                  <ListGroupItem>
                    <strong>Date Issue:</strong> {formMeta.issueDate}
                  </ListGroupItem>
                  <ListGroupItem>
                    <strong>Author:</strong> {formMeta.author}
                  </ListGroupItem>
                  <ListGroupItem>
                    <strong>Country:</strong> {formMeta.country}
                  </ListGroupItem>
                  <ListGroupItem>
                    <strong>Institution:</strong> {formMeta.institution}
                  </ListGroupItem>
                </ListGroup>
                <Button
                  style={transparentButtonStyle}
                  variant="primary"
                  onClick={handleDisplayElement}
                >
                  {isDisplayElements ? "Hide Elements" : " View Elements"}
                </Button>
                <Button
                  variant="primary transparent"
                  style={buttonStyle}
                  onClick={handleConfirmUpload}
                  disabled={mutation.isLoading}
                >
                  {mutation.isLoading ? "Uploading..." : "Standardize"}
                </Button>
              </div>
            </div>
            {isDisplayElements && (
              <div style={elementContainerStyle}>
                <h5>Elements:</h5>

                {formMeta.stagings.map((element, index) => (
                  <QuestionnaireElement element={element} />
                ))}
              </div>
            )}
          </Container>
        )}
      </section>
    </div>
  );
};

const QuestionnaireElement = ({ element }) => {
  const elementStyle = {
    marginBottom: "20px",
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    boxShadow: "none",
    background: "none",
    // Remove background color
  };

  const headerStyle = {
    backgroundColor: "transparent", // Remove header background color
    borderBottom: "1px solid #e0e0e0",
    padding: "10px 15px",
    display: "inline-flex",
  };

  const toggleStyle = {
    color: "black", // Text color is now black
    fontWeight: "bold",
    textDecoration: "none",
    display: "inline-flex",
  };

  return (
    <Card style={elementStyle}>
      <Card.Header style={headerStyle}>
        <Accordion.Toggle
          as={Button}
          variant="link"
          eventKey={element.elementName}
          style={toggleStyle}
        >
          {element.elementOrder} {". "} {element.elementName} {"  "}{" "}
          <p style={{ fontWeight: "100", marginLeft: "10px" }}>
            Sample Value: {element.sampleValues}
          </p>
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={element.elementName}>
        <Card.Body>
          <strong>{element.elementName}</strong>
          <p>Element Description: {element.elemDescr}</p>
          <p>Element Number: {element.elementOrder} </p>
          <p>Sample Value: {element.sampleValues}</p>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default ConfirmUploadPage;

// import React, { useState } from "react";
// import {
//   Container,
//   Button,
//   ListGroup,
//   ListGroupItem,
//   Card,
//   Accordion,
// } from "react-bootstrap";
// import MetaTags from "react-meta-tags";
// import useSharedStore from "../../store";
// // import { useToaster } from "react-hot-toast";
// import { useMutation } from "react-query";
// import { toast } from "react-toastify";
// import { useHistory } from "react-router-dom";

// const API_URL = "http://localhost:7001/api/insert-data";

// const ConfirmUploadPage = () => {
//   const sharedStore = useSharedStore(); // Access the store
//   const history = useHistory();

//   // Use sharedStore.uploadedData to get the data from the store
//   const formData = sharedStore.uploadedData;

//   console.log(formData);

//   const [isConfirmed, setIsConfirmed] = useState(false);
//   const [isDisplayElements, setDisplayElements] = useState(false);

//   // Define a mutation function
//   const postFormData = async (formData) => {
//     try {
//       const response = await fetch(API_URL, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(formData),
//       });
//       if (!response.ok) {
//         throw new Error("Error posting data to the server");
//       }
//       return response.json();
//     } catch (error) {
//       // toast.error(`Error: ${error.message}`);
//       throw new Error(`Error: ${error.message}`);
//     }
//   };

//   // Use the useMutation hook to create a mutation function
//   const mutation = useMutation(postFormData);

//   const handleConfirmUpload = () => {
//     // Handle the confirmation logic here (e.g., send data to the server)
//     // You can also navigate to another page or perform any other action

//     // sharedStore.setUploadedData(formData);

//     // Update the state to indicate that the upload has been confirmed
//     setIsConfirmed(true);

//     console.log("data is" + { formData });
//     mutation
//       .mutate(formData)
//       .then((data) => {
//         // Handle success with the returned data if needed
//         console.log("Upload successful:", data);
//         history.push("/confirm-upload");
//       })
//       .catch((error) => {
//         toast.error(`Error: ${error.message}`); // Display the error message
//       });
//   };

//   const elementContainerStyle = {
//     backgroundColor: "#f0f0f0",
//     padding: "20px",
//     marginTop: "20px",
//     borderRadius: "8px",
//   };

//   const containerStyle = {
//     // background: "#f0f0f0",
//     marginTop: "40px",
//     // padding: "20px",
//   };

//   const formContainerStyle = {
//     backgroundColor: "#f0f0f0",
//     padding: "20px",
//     borderRadius: "8px",
//   };

//   const buttonStyle = {
//     margin: "8px", // Adjust margin spacing as needed
//     padding: "8px 16px", // Adjust padding as needed
//     backgroundColor: "#007BFF", // Custom button background color
//     color: "#FFFFFF", // Custom button text color
//     border: "none", // Remove button border
//     borderRadius: "4px", // Add button border radius
//     cursor: "pointer", // Change cursor on hover
//   };

//   const transparentButtonStyle = {
//     ...buttonStyle,
//     backgroundColor: "transparent", // Transparent background
//     color: "#007BFF", // Custom text color for transparent button
//     border: "1px solid #007BFF", // Add border to transparent button
//   };

//   const handleDisplayElement = () => {
//     setDisplayElements(!isDisplayElements);
//   };

//   return (
//     // <div className="elements-page" style={{ marginTop: "40px" }}>
//     <div style={containerStyle}>
//       <MetaTags>
//         <title>Home | Confirm Upload</title>
//       </MetaTags>

//       <section className="py-5">
//         {formData && (
//           <Container>
//             {/* <div className="confirmation-form bg-light p-4 rounded shadow"> */}
//             <div style={formContainerStyle}>
//               <h3 className="mb-4">Confirm Upload</h3>
//               <div>
//                 <ListGroup>
//                   <ListGroupItem>
//                     <strong>Questionnaire Name:</strong> {formData.title}
//                   </ListGroupItem>
//                   <ListGroupItem>
//                     <strong>Description:</strong> {formData.description}
//                   </ListGroupItem>
//                   <ListGroupItem>
//                     <strong>Date Issue:</strong> {formData.issueDate}
//                   </ListGroupItem>
//                   <ListGroupItem>
//                     <strong>Author:</strong> {formData.author}
//                   </ListGroupItem>
//                   <ListGroupItem>
//                     <strong>Country:</strong> {formData.country}
//                   </ListGroupItem>
//                   <ListGroupItem>
//                     <strong>Institution:</strong> {formData.institution}
//                   </ListGroupItem>
//                   <ListGroupItem>
//                     <strong>Questionnaire Elements:</strong>{" "}
//                     {/* {formData.elements.length ?? 0} */}0
//                   </ListGroupItem>
//                 </ListGroup>
//                 <Button
//                   style={transparentButtonStyle}
//                   variant="primary"
//                   onClick={handleDisplayElement}
//                 >
//                   {isDisplayElements ? "Hide Elements" : " View Elements"}
//                 </Button>
//                 <Button
//                   variant="primary transparent"
//                   style={buttonStyle}
//                   onClick={handleConfirmUpload}
//                   disabled={mutation.isLoading}
//                 >
//                   {mutation.isLoading ? "Uploading..." : "Standardize"}
//                 </Button>
//               </div>
//             </div>
//             {isDisplayElements && (
//               <div style={elementContainerStyle}>
//                 <h5>Elements:</h5>

//                 {formData.stagings.map((element, index) => (
//                   <QuestionnaireElement element={element} />
//                 ))}
//               </div>
//             )}
//           </Container>
//         )}
//       </section>
//     </div>
//   );
// };

// const QuestionnaireElement = ({ element }) => {
//   const elementStyle = {
//     marginBottom: "20px",
//     border: "1px solid #e0e0e0",
//     borderRadius: "8px",
//     boxShadow: "none",
//     background: "none", // Remove background color
//   };

//   const headerStyle = {
//     backgroundColor: "transparent", // Remove header background color
//     borderBottom: "1px solid #e0e0e0",
//     padding: "10px 15px",
//   };

//   const toggleStyle = {
//     color: "black", // Text color is now black
//     fontWeight: "bold",
//     textDecoration: "none",
//   };

//   return (
//     <Card style={elementStyle}>
//       <Card.Header style={headerStyle}>
//         <Accordion.Toggle
//           as={Button}
//           variant="link"
//           eventKey={element.elementName}
//           style={toggleStyle}
//         >
//           {element.elementOrder} {". "} {element.elementName}
//         </Accordion.Toggle>
//       </Card.Header>
//       <Accordion.Collapse eventKey={element.elementName}>
//         <Card.Body>
//           <strong>{element.elementName}</strong>
//           <p> Element Description: {element.elementDescription}</p>
//           <p>Element Number: {element.elementOrder} </p>
//           <p>Sample Value: {element.sampleValues}</p>
//         </Card.Body>
//       </Accordion.Collapse>
//     </Card>
//   );
// };

// export default ConfirmUploadPage;
