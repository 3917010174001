import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  };

  const titleStyle = {
    fontSize: "24px",
    marginBottom: "10px",
  };

  return (
    <div style={containerStyle}>
      <h2 style={titleStyle}>404 - Not Found</h2>
      <p>
        The page you are looking for does not exist. Go back to{" "}
        <Link to="/">QuestMap</Link>
      </p>
    </div>
  );
};

export default NotFound;
