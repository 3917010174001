const columns = [
  {
    name: "FieldID",
    selector: (row) => row.idsrFieldID,
  },
  {
    name: "Field Name",
    selector: (row) => row.fieldName,
    wrap: true,
  },
  {
    name: "Field Type",
    selector: (row) => row.fieldType,
    wrap: true,
    grow: 0.3,
  },
  {
    name: "Description",
    selector: (row) => row.fieldDescr,
    wrap: true,
  },
  {
    name: "Tags",
    selector: (row) => row.tag.tagText,
    wrap: true,
  },
  {
    name: "OMOP Concept ID",
    selector: (row) => {
      const conceptMapping = row.conceptMappings && row.conceptMappings.length > 0 ? row.conceptMappings[0] : null;
      return conceptMapping ? conceptMapping.conceptID : "";
    },
    cell: (row) => {
      const conceptMapping = row.conceptMappings && row.conceptMappings.length > 0 ? row.conceptMappings[0] : null;
      return (
        <a
          href={`https://athena.ohdsi.org/search-terms/terms/${conceptMapping?.conceptID}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {conceptMapping?.conceptID}
        </a>
      );
    },
  },
  // {
  //   name: "OMOP Concept ID",
  //   selector: (row) => row.conceptMappings?.conceptID,
  //   cell: (row) => (
  //     <a
  //       href={`https://athena.ohdsi.org/search-terms/terms/${row.conceptMapping?.conceptID}`}
  //       target="_blank"
  //       rel="noopener noreferrer"
  //     >
  //       {row.conceptMapping?.conceptID}
  //     </a>
  //   ),
  // },
  // { more than 1 mappings
  //   name: "OMOP Concept IDs",
  //   selector: (row) => {
  //     const conceptMappings = row.conceptMappings || [];
  //     return conceptMappings.map((mapping) => mapping.conceptID).join(", ");
  //   },
  //   cell: (row) => {
  //     const conceptMappings = row.conceptMappings || [];
  //     return conceptMappings.map((mapping) => (
  //       <a
  //         key={mapping.conceptID}
  //         href={`https://athena.ohdsi.org/search-terms/terms/${mapping.conceptID}`}
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         {mapping.conceptID}
  //       </a>
  //     ));
  //   },
  // },
];

export default columns;
