import React, { useEffect, useState } from "react";
import { Col, Container, Row, Input } from "reactstrap";
import Section from "../../components/Section";
import MetaTags from "react-meta-tags";
import QuestionnaireTable from "../../components/QuestionnaireTable";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import columns from "../../utils/questionnaire";
import axios from "axios";
import AlphabetBadgeList from "../../components/AlphabetBadgeList";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import debounce from "lodash.debounce";
import stores from "../../store";
const { useSharedStore } = stores;

const fetchData = async () => {
  const response = await axios.get(
    "https://us-central1-questmap-mubas.cloudfunctions.net/getIdsrQListing"
  );
  return response.data || {};
};

const QuestionnairesPage = () => {
  const history = useHistory();

  const [filteredQuestionnaires, setFilteredQuestionnaires] = useState([]);
  const [allQuestionnaires, setAllQuestionnaires] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedLetter, setSelectedLetter] = useState("All"); // Default to "All"
  const [countryOptions, setCountryOptions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [questionnaireTypes, setQuestionnaireTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Use a single isLoading state

  useEffect(() => {
    const applyFiltersWithDebounce = debounce(() => {
      applyFilters(selectedCountry, selectedType, selectedLetter, searchTerm);
    }, 300);

    applyFiltersWithDebounce(); // Initial call without debounce
    // Cleanup the debounce function on component unmount
    return () => applyFiltersWithDebounce.cancel();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, selectedType, selectedLetter, searchTerm]);

  useQuery("questionnaireData", fetchData, {
    onSuccess: (data) => {
      setAllQuestionnaires(data.idsrqlisting || []);
      setFilteredQuestionnaires(data.idsrqlisting || []);
      setQuestionnaireTypes(data.questionnairetype || []);

      const initialCountries = getDistinctCountries(data.idsrqlisting || []);
      setCountries(initialCountries);
      setCountryOptions(initialCountries);
    },
    onError: (error) => {
      console.error("Error fetching data:", error);
    },
  });

  // setting the selected questionnaire in the Zustand store
  const setSelectedQuestionnaire = useSharedStore(
    (state) => state.setSelectedQuestionnaire
  );

  // Add this function to handle clicks on the ID column
  const handleIDClick = (idsrQPID) => {
    // Pass the ID to the Zustand store
    setSelectedQuestionnaire(
      filteredQuestionnaires.find((q) => q.idsrQPID === idsrQPID)
    );

    // Navigate to the next page
    history.push(`/elements?target=${idsrQPID}`);
  };

  const handleLetterSelect = (letter) => {
    setSelectedLetter(letter);

    if (letter === "All") {
      // Show all countries when "All" is selected
      setCountryOptions(countries);
    } else {
      // Filter countries that start with the selected letter
      const filteredCountries = countries.filter(
        (country) =>
          country && country.toLowerCase().startsWith(letter.toLowerCase())
      );
      setCountryOptions([...filteredCountries]);

      applyFilters(selectedCountry, selectedType, letter, searchTerm);
    }
  };

  const handleCountryChange = (event) => {
    const country = event.target.value;
    setSelectedCountry(country);
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const applyFilters = (
    selectedCountryValue,
    selectedTypeValue,
    selectedLetterValue,
    searchTermValue
  ) => {
    setIsLoading(true);

    setTimeout(() => {
      const filteredFields = allQuestionnaires.filter((field) => {
        const matchesSearchTerm = field.title
          .toLowerCase()
          .includes(searchTermValue.toLowerCase());
        const matchesCountry =
          !selectedCountryValue ||
          selectedCountryValue === "" ||
          field.country === selectedCountryValue;

        const matchesType =
          !selectedTypeValue ||
          selectedTypeValue === "" ||
          field.qTypeMaps.some(
            (qTypeMap) => qTypeMap.qTypeID === selectedTypeValue
          );

        const matchesLetter =
          selectedLetterValue === "All" ||
          !selectedLetterValue ||
          selectedLetterValue === "" ||
          (field.country &&
            field.country
              .toLowerCase()
              .startsWith(selectedLetterValue.toLowerCase()));

        return (
          matchesSearchTerm && matchesCountry && matchesType && matchesLetter
        );
      });

      setFilteredQuestionnaires(filteredFields);
      setIsLoading(false); // Set isLoading to false once, after filtering is done
    }, 300);
  };

  const getDistinctCountries = (questionnaireData) => {
    const countries = questionnaireData.map(
      (questionnaire) => questionnaire.country
    );
    return [...new Set(countries)];
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Questionnaires</title>
      </MetaTags>
      <Section page="Questionnaires" />
      <section className="section">
        <Container>
          <div>
            <h5 style={{ fontWeight: "semibold", fontSize: "12pt" }}>
              Countries
            </h5>
            <AlphabetBadgeList
              selectedLetter={selectedLetter}
              onSelectLetter={handleLetterSelect}
            />
          </div>
          <Row className="container mt-4">
            <div className="me-lg-5">
              <div className="job-list-header">
                <Row className="g-2">
                  <Col lg={3} md={6}>
                    <div className="filler-job-form">
                      <select
                        className="form-select"
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                      >
                        <option value="">Select Type</option>
                        {questionnaireTypes.map((type) => (
                          <option key={type.qTypeID} value={type.qTypeID}>
                            {type.type}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col lg={3} md={6}>
                    <div className="filler-job-form">
                      <select
                        className="form-select"
                        value={selectedCountry}
                        onChange={handleCountryChange}
                      >
                        <option value="">Select Country</option>
                        {countryOptions.map((tag, index) => (
                          <option key={index} value={tag}>
                            {tag}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>

                  <Col lg={3} md={6}>
                    <div className="filler-job-form">
                      <Input
                        type="search"
                        className="form-control filter-job-input-box-option"
                        id="exampleFormControlInput1"
                        placeholder="Questionnaire Title... "
                        onChange={handleSearchTermChange}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>
          <div className="mt-3">
            {isLoading ? (
              <LoadingSpinner caption="Loading Data..." />
            ) : (
              <div>
                <QuestionnaireTable
                  title="Questionnaires"
                  data={filteredQuestionnaires}
                  columns={columns}
                  onIDClick={handleIDClick} // Pass the ID click handler
                />
              </div>
            )}
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default QuestionnairesPage;
