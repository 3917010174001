import React, { useState, useEffect } from "react";
import {
  Container,
  Collapse,
  NavbarToggler,
  NavItem,
} from "reactstrap";

import { Link, withRouter } from "react-router-dom";

const NavBar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  //scroll navbar
  const [navClass, setnavClass] = useState(false);

  //menu activation

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    var matchingMenuItem = null;
    var ul = document.getElementById("navbarCollapse");
    var items = ul.getElementsByTagName("a");

    removeActivation(items);
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }

    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });
  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active");
        }
      }
    }
  };

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement.parentElement.parentElement;

    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  return (
    <React.Fragment>
      <nav
        className={"navbar navbar-expand-lg fixed-top sticky p-0 " + navClass}
        id="navigation"
      >
        <Container fluid className="custom-container">
          <Link className="navbar-brand text-dark fw-bold me-auto" to="/">
            {/* <img src={darkLogo} height="22" alt="" className="logo-dark" />*/}
            QuestMap
          </Link>
          <div>
            <NavbarToggler
              className="me-3"
              type="button"
              onClick={() => toggle()}
            >
              <i className="mdi mdi-menu"></i>
            </NavbarToggler>
          </div>
          <Collapse
            isOpen={isOpen}
            className="navbar-collapse"
            id="navbarCollapse"
          >
            <ul className="navbar-nav mx-auto navbar-center">
              {/* <NavItem className="dropdown dropdown-hover">
                <NavLink
                  to="/#"
                  id="homedrop"
                  className="arrow-none"
                  onClick={() => setHome(!home)}
                >
                  Home <div className="arrow-down"></div>
                </NavLink>
                <ul
                  className={classname("dropdown-menu dropdown-menu-center", {
                    show: home,
                  })}
                  aria-labelledby="homedrop"
                >
                  <li>
                    <Link className="dropdown-item" to="/">
                      Home 1
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/layout2">
                      Home 2
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/layout3">
                      Home 3
                    </Link>
                  </li>
                </ul>
              </NavItem> */}

              <NavItem>
                {" "}
                <Link className="nav-link" to="/upload-data">
                  Upload Questionnaire
                </Link>
              </NavItem>

              <NavItem>
                {" "}
                <Link className="nav-link" to="/simulation">
                  Standardization
                </Link>
              </NavItem>

              <NavItem>
                {" "}
                <Link className="nav-link" to="/questionnaires">
                  Questionnaires
                </Link>
              </NavItem>

              <NavItem>
                {" "}
                <Link className="nav-link" to="standardized-fields">
                  Standardized Fields
                </Link>
              </NavItem>

              <NavItem>
                {" "}
                <Link className="nav-link" to="/contact-us">
                  Contact
                </Link>
              </NavItem>
            </ul>
          </Collapse>
        </Container>
      </nav>
    </React.Fragment>
  );
};

export default withRouter(NavBar);
