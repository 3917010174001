import {
    StyleSheet,
} from "@react-pdf/renderer";

// styles.js
export const containerStyle = {
    marginTop: "8%",
    paddingBottom: "5%",
};

export const buttonStylesLink = {
    backgroundColor: 'transparent',
    border: 'none',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    // Add more custom styles here
};

export const commonCodebookButtonStyles = {
    textDecoration: "none",
    padding: "7px",
    color: "black",
    backgroundColor: "#ffffff",  // Corrected color code
    border: "1px solid lightgrey",
    height: "40px",
    display: "flex",
    alignItems: "center",  // To vertically center the content
    justifyContent: "center"  // To horizontally center the content
};

export const buttonStyle = {
    height: "40px",
    backgroundColor: "#0d6efd",
    borderColor: "#0d6efd",
    width: "200px",
    borderRadius: "0",
};

export const resetButtonStyle = {
    height: "40px",
    backgroundColor: "white",
    color: "black",
    borderColor: "lightgrey",
    width: "100px",
    borderRadius: "0",
    marginRight: "20px",
};

export const searchPlaceholderStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "800px",
    margin: "0 auto",
    height: "35vh",
    backgroundColor: "#f9f9f9",
    borderRadius: "10px",
    padding: "20px",
};

export const searchIconStyle = {
    marginBottom: "50px",
};

export const colorColumnStyle = {
    flex: "0 0 25px", // Fixed width for the colored column
    backgroundColor: "green", // Default color
    height: "25px",
    borderRadius: "50%",
    marginRight: "20px",
    width: "25px",
};



export const customLabels = {
    title: "Title:",
    description: "Description:",
    country: "Country:",
    author: "Author:",
    type: "Type:",
    institution: "Institution:",
    version: "Version:",
    issueDate: "Issue Date:",
};
export const customLabels2 = {
    title: "Title:",
    description: "Description:",
    country: "Country:",
    dateIssue: "Date of Issue:",
    institution: "Institution:",
};

export const styles = StyleSheet.create({
    smallHeader: {
        fontSize: 10,
        textAlign: "right",
        paddingBottom: 5, // Padding for the small header to give space above the line
        borderBottomWidth: 1, // Line under the small header
        borderBottomColor: "black",
        borderBottomStyle: "solid",
        marginBottom: 15,
    },
    table: {
        display: "table",
        width: "100%",
        borderStyle: "solid",
        borderWidth: 0.5, // Thinner border
        borderColor: "#e0e0e0",
    },
    header: {
        width: "100%",
        backgroundColor: "grey",
        padding: 13,
        borderStyle: "solid",
        borderWidth: 0.5, // Thinner border
        borderColor: "#e0e0e0",
        fontSize: 18,
        fontWeight: "bold",
        color: "white",
        textAlign: "left",
    },

    matchHeaderGREEN: {
        marginTop: "50px",
        marginBottom: "25px",
        width: "100%",
        backgroundColor: "green",
        padding: 13,
        borderStyle: "solid",
        borderWidth: 0.5, // Thinner border
        borderColor: "#e0e0e0",
        fontSize: 15,
        fontWeight: "bold",
        color: "white",
        textAlign: "left",
    },
    matchHeaderORANGE: {
        marginTop: "50px",
        marginBottom: "25px",
        width: "100%",
        backgroundColor: "orange",
        padding: 13,
        borderStyle: "solid",
        borderWidth: 0.5, // Thinner border
        borderColor: "#e0e0e0",
        fontSize: 15,
        fontWeight: "bold",
        color: "white",
        textAlign: "left",
    },
    matchHeaderRED: {
        marginTop: "50px",
        marginBottom: "25px",
        width: "100%",
        backgroundColor: "red",
        padding: 13,
        borderStyle: "solid",
        borderWidth: 0.5, // Thinner border
        borderColor: "#e0e0e0",
        fontSize: 15,
        fontWeight: "bold",
        color: "white",
        textAlign: "left",
    },
    tableRow: {
        flexDirection: "row",
    },
    tableCol1: {
        // Smaller Column
        width: "30%",
        borderStyle: "solid",
        borderWidth: 0.5, // Thinner border
        borderColor: "#e0e0e0",
        textAlign: "left",
    },
    tableCol2: {
        // Larger Column
        width: "70%",
        borderStyle: "solid",
        borderWidth: 0.5, // Thinner border
        borderColor: "#e0e0e0",
        textAlign: "left",
    },
    tableCell: {
        marginTop: 5,
        marginBottom: 5,
        fontSize: 11,
        textAlign: "left",
        padding: 7,
    },


    table2: {
        marginTop: "2%",

        display: "table",
        width: "100%",
        borderStyle: "solid",
        borderWidth: 0.5, // Thinner border
        borderColor: "#e0e0e0",
    },
    header2: {
        width: "100%",
        backgroundColor: "grey",
        padding: 10,
        borderStyle: "solid",
        borderWidth: 0.5, // Thinner border
        borderColor: "#e0e0e0",
        fontSize: 12,
        fontWeight: "bold",
        color: "white",
        textAlign: "left",
    },
    tableRow2: {
        flexDirection: "row",
    },
    tableCol1_2: {
        // Smaller Column
        width: "30%",
        borderStyle: "solid",
        borderWidth: 0.5, // Thinner border
        borderColor: "#e0e0e0",
        textAlign: "left",
    },
    tableCol2_2: {
        // Larger Column
        width: "70%",
        borderStyle: "solid",
        borderWidth: 0.5, // Thinner border
        borderColor: "#e0e0e0",
        textAlign: "left",
    },
    tableCell2: {
        marginTop: 5,
        marginBottom: 5,
        fontSize: 11,
        textAlign: "left",
        padding: 5,
    },
});
