import React from "react";
import Section from "../../components/Section";
import ContactContent from "../../components/ContactContent";
import MetaTags from "react-meta-tags";

const ContactPage = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Contact | QuestMap</title>
      </MetaTags>
      <Section page="Contact Us" />
      <ContactContent />
    </React.Fragment>
  );
};

export default ContactPage;
