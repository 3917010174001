// import React from "react";
// import { Form } from "react-bootstrap";
// import { Col, Container, Row, Input, Label } from "reactstrap";

// const ContactContent = () => {
//   return (
//     <React.Fragment>
//       <section className="section">
//         <div style={{ margin: "auto", maxWidth: "500px" }}>
//           <Row className="align-items-center mt-5">
//             <Container wid>
//               <div className="section-title mt-4 mt-lg-0">
//                 <h3 className="title text-center ">Get in touch</h3>
//                 <p className="text-muted text-center ">
//                   Send use a message below.
//                 </p>
//                 <Form
//                   method="post"
//                   className="contact-form mt-4"
//                   name="myForm"
//                   id="myForm"
//                 >
//                   <span id="error-msg"></span>
//                   <Row>
//                     <Col lg={12}>
//                       <div className="mb-3">
//                         <Label htmlFor="nameInput" className="form-label">
//                           Name
//                         </Label>
//                         <Input
//                           type="text"
//                           name="name"
//                           id="name"
//                           className="form-control"
//                           placeholder="Enter your name"
//                         />
//                       </div>
//                     </Col>
//                     <Col md={6}>
//                       <div className="mb-3">
//                         <Label htmlFor="emailInput" className="form-label">
//                           Email
//                         </Label>
//                         <Input
//                           type="email"
//                           className="form-control"
//                           id="emaiol"
//                           name="email"
//                           placeholder="Enter your email"
//                         />
//                       </div>
//                     </Col>
//                     <Col md={6}>
//                       <div className="mb-3">
//                         <Label htmlFor="subjectInput" className="form-label">
//                           Subject
//                         </Label>
//                         <Input
//                           type="text"
//                           className="form-control"
//                           name="subject"
//                           placeholder="Enter your subject"
//                         />
//                       </div>
//                     </Col>
//                     <Col lg={12}>
//                       <div className="mb-3">
//                         <Label htmlFor="meassageInput" className="form-label">
//                           Your Message
//                         </Label>
//                         <textarea
//                           className="form-control"
//                           placeholder="Enter your message"
//                           name="comments"
//                           rows="3"
//                         ></textarea>
//                       </div>
//                     </Col>
//                   </Row>
//                   <div className="text-end">
//                     <button
//                       type="button"
//                       id="submit"
//                       name="submit"
//                       className="btn btn-primary"
//                     >
//                       {" "}
//                       Send Message <i className="uil uil-message ms-1"></i>
//                     </button>
//                   </div>
//                 </Form>
//               </div>
//             </Container>
//           </Row>
//         </div>
//       </section>
//     </React.Fragment>
//   );
// };

// export default ContactContent;

import React from "react";
import { Container, Row } from "reactstrap";

const ContactContent = () => {
  const email = "ailab@poly.ac.mw";
  return (
    <React.Fragment>
      <section className="section">
        <div style={{ margin: "auto", maxWidth: "500px" }}>
          <Row className="align-items-center mt-5">
            <Container>
              <div className="section-title mt-4 mt-lg-0">
                {/* <h3 className="title text-center">Contact Us via email</h3> */}
                <h5 className="text-muted text-center">
                  Please tell us what you think of this tool. Did you find it
                  useful? Email us at <a href={`mailto:${email}`}>{email}</a>.
                </h5>
              </div>
            </Container>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ContactContent;
