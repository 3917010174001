import React from "react";
import BootstrapButton from "react-bootstrap/Button";
import BootstrapForm from "react-bootstrap/Form";
import BootstrapModal from "react-bootstrap/Modal";

function EditDescriptionModal({
  show,
  handleClose,
  handleSave,
  textInputRef,
  defaultValue,
}) {
  return (
    <BootstrapModal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>Edit Description</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <BootstrapForm>
          <BootstrapForm.Group
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
          >
            <BootstrapForm.Label></BootstrapForm.Label>
            <BootstrapForm.Control
              as="textarea"
              rows={3}
              ref={textInputRef}
              defaultValue={defaultValue || ""}
            />
          </BootstrapForm.Group>
        </BootstrapForm>
      </BootstrapModal.Body>
      <BootstrapModal.Footer>
        <BootstrapButton variant="secondary" onClick={handleClose}>
          Close
        </BootstrapButton>
        <BootstrapButton variant="primary" onClick={handleSave}>
          Save Changes
        </BootstrapButton>
      </BootstrapModal.Footer>
    </BootstrapModal>
  );
}

export default EditDescriptionModal;
