import searchIcon from "../../assets/images/search.png";

 const SearchInit = ({ buttonText }) => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "800px",
    margin: "0 auto",
    height: "35vh",
    backgroundColor: "#f9f9f9",
    borderRadius: "10px",
    padding: "20px",
  };

  return (
    <div className="search-placeholder" style={containerStyle}>
      <img
        src={searchIcon}
        height="70px"
        alt="Search Icon"
        className="search-icon"
        style={{ marginBottom: "50px" }}
      />
      <h4 className="placeholder-text">{buttonText}</h4>
    </div>
  );
};

export default SearchInit