// import { firstSearch } from "./firstSearch";
import batchSearch from "./batchSearch";

async function runSimulation(currentSearch, setCurrentSearch, data, reRunSearchBool) {
  let response;

  console.log('here is data', data[0]);

  // response = await firstSearch(currentSearch, setCurrentSearch, data, reRunSearchBool);

  response = await batchSearch(currentSearch, setCurrentSearch, data, reRunSearchBool);

  // console.log("here is response", response);

  return response;
}

export { runSimulation };
