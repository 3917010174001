import React from "react";

const AlphabetBadgeList = ({ selectedLetter, onSelectLetter }) => {
  const letters = "abcdefghijklmnopqrstuvwxyz"; // All alphabetic letters

  const handleLetterClick = (letter) => {
    onSelectLetter(letter);
  };

  return (
    <div className="d-flex flex-wrap">
      <span
        className={`badge badge-pill black-text badge-primary m-1 ${
          selectedLetter === "All" ? "selected-letter" : ""
        }`}
        onClick={() => handleLetterClick("All")} // Add onClick for "All" button
      >
        ALL
      </span>
      {letters.split("").map((letter, index) => (
        <span
          key={index}
          className={`badge badge-pill black-text badge-primary m-1 ${
            selectedLetter === letter ? "selected-letter" : ""
          }`}
          onClick={() => handleLetterClick(letter)}
        >
          {letter.toUpperCase()}
        </span>
      ))}
    </div>
  );
};

export default AlphabetBadgeList;
