import React, { useEffect, useState } from "react";
import { Col, Container, Row, Input, Form } from "reactstrap";
import Section from "../../components/Section";
import MetaTags from "react-meta-tags";
import Table from "../../components/Table";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import columns from "../../utils/IdsrColumns";
import axios from "axios";

const StandardizedPage = () => {
  const [filteredFields, setFilteredFields] = useState([]);
  const [standardizedFields, setStandardisedFields] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTags, setSelectedTags] = useState([]); // Use an array for multiple selections
  const [isFiltering, setIsFiltering] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Trigger filtering when selectedTags change
    filterFields();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags]); // Watch selectedTags for changes

  const filterFields = () => {
    const filteredFields = standardizedFields.filter((field) => {
      const matchesSearchTerm = field.fieldName
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const matchesTag =
        selectedTags.length === 0 || selectedTags.includes(field.tag.tagText);
      return matchesSearchTerm && matchesTag;
    });

    setFilteredFields(filteredFields);
    setIsFiltering(false);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://us-central1-questmap-mubas.cloudfunctions.net/getIdsrFieldsCollection"
      );
      setStandardisedFields(response.data.idsr);
      setFilteredFields(response.data.idsr);
      const uniqueTags = extractUniqueTags(response.data.idsr);
      setTagOptions(uniqueTags);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedTags([]);
    } else {
      setSelectedTags(tagOptions);
    }
    setSelectAll(!selectAll);
  };

  const handleTagChange = (event) => {
    setIsFiltering(true);

    setTimeout(() => {
      const selectedTag = event.target.value;
      if (selectedTags.includes(selectedTag)) {
        // If the tag is already selected, remove it
        setSelectedTags(selectedTags.filter((tag) => tag !== selectedTag));
      } else {
        // If the tag is not selected, add it
        setSelectedTags([...selectedTags, selectedTag]);
      }
      setSelectAll(false); // Uncheck "All" when individual tags are selected
      setIsFiltering(false);
    }, 300);
  };

  const handleSearch = (e) => {
    e.preventDefault();

    setIsFiltering(true);

    setTimeout(() => {
      const filteredFields = standardizedFields.filter((field) => {
        const matchesSearchTerm = field.fieldName
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        const matchesTag =
          selectedTags.length === 0 || selectedTags.includes(field.tag.tagText);
        return matchesSearchTerm && matchesTag;
      });

      setFilteredFields(filteredFields);
      setIsFiltering(false);
    }, 300);
  };

  const extractUniqueTags = (data) => {
    const uniqueTags = new Set();

    data.forEach((field) => {
      const tagText = field.tag && field.tag.tagText;

      if (tagText) {
        uniqueTags.add(tagText.trim());
      }
    });

    return Array.from(uniqueTags);
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Browser Standardized Fields</title>
      </MetaTags>
      <Section page="Standardized Fields" />
      <section className="section">
        <Container>
          <Row>
            <div className="me-lg-5">
              <div className="job-list-header">
                <Form action="#">
                  <Row className="g-2">
                    <Col lg={3} md={6}>
                      <div className="filler-job-form">
                        <Input
                          type="search"
                          className="form-control filter-job-input-box-option"
                          id="exampleFormControlInput1"
                          placeholder="Field Name... "
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg={3} md={6}>
                      <button
                        onClick={handleSearch}
                        className="btn btn-primary w-100"
                      >
                        <i className="uil uil-filter"></i> Search
                      </button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Row>
          <div>
            {isLoading ? (
              <div style={{ marginTop: "80px" }}>
                <LoadingSpinner caption="Loading Data..." />
              </div>
            ) : (
              <Row mt={3} style={{ marginTop: "20px" }}>
                <Col lg={2} md={6} style={{ marginTop: "12px" }}>
                  <div className="filler-job-form">
                    <fieldset>
                      <legend style={{ zoom: 0.8 }}>Select Tags</legend>
                      <label>
                        <input
                          type="checkbox"
                          value="Select All"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />{" "}
                        All
                      </label>
                      {tagOptions.map((tag, index) => (
                        <div key={index}>
                          <label>
                            <input
                              type="checkbox"
                              value={tag}
                              checked={selectedTags.includes(tag)}
                              onChange={handleTagChange}
                            />{" "}
                            {tag}
                          </label>
                        </div>
                      ))}
                    </fieldset>
                  </div>
                </Col>
                <Col lg={10}>
                  {isFiltering ? (
                    <div style={{ marginTop: "80px" }}>
                      <LoadingSpinner caption="Loading Data..." />
                    </div>
                  ) : (
                    <div className="vertical-line-table">
                      <Table data={filteredFields} columns={columns} />
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default StandardizedPage;