import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
    const footerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '65px',
        backgroundColor: '#000000', // Black background
        // backgroundColor: '#f8f9fa',
        // backgroundColor: '#e2e2e2',
        padding: '20px 0',
        position: 'fixed',
        bottom: '0',
        width: '100%',
        zIndex: '1000', // Set a high z-index to make sure it's above other elements
    };

    const linkStyle = {
        color: '#007BFF',
        textDecoration: 'none',
        fontWeight: '500',
    };

    const textStyle = {
        fontSize: '12px',
        paddingTop: "10px",
        marginBottom: '0',
        color: '#ffffff', // White text color
        lineHeight: '1.5', // Adjust line height to vertically center the text
    };

    return (
        <footer style={footerStyle}>
            <Container>
                <Row>
                    <Col>
                        <p style={textStyle}>
                            &copy; 2024 <a style={linkStyle} href="https://www.healthdataafrica.org/" target="_blank" rel="noopener noreferrer">Health Data Africa</a>, All rights reserved.
                        </p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
