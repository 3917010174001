
export default async function batchSearch(
    currentSearch,
    setCurrentSearch,
    data,
    reRunSearchBool
) {
    setCurrentSearch("Standardizing... Please wait.");

    try {

        const response = await fetch("https://us-central1-fiber-db070.cloudfunctions.net/batchSearch", {

            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ data: data, reRunSearchBool: reRunSearchBool }),
        });

        const result = await response.json();
        // console.log("Here is the result: ", result);

        return result || [];

    } catch (error) {
        console.error("Error during batch search:", error);
        setCurrentSearch("An error occurred during the search. Please try again.");
    }
}

// export default async function batchSearch(
//     currentSearch,
//     setCurrentSearch,
//     data,
//     reRunSearchBool
// ) {
//     setCurrentSearch("Standardizing... Please wait.");

//     const response = await fetch("http://localhost:5500/api/batchSearch", {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ data: data, reRunSearchBool: reRunSearchBool }),
//     });

//     console.log(response.data);

//     const reader = response.body.getReader();
//     const decoder = new TextDecoder("utf-8");

//     let result = "";

//     while (true) {
//         const { done, value } = await reader.read();
//         if (done) break;

//         result += decoder.decode(value, { stream: true });

//         const lines = result.split('\n');
//         for (const line of lines) {
//             if (line.trim()) {
//                 try {
//                     const parsedLine = JSON.parse(line.replace(/^data: /, ''));
//                     if (parsedLine.progress) {
//                         setCurrentSearch(parsedLine.progress);
//                     } else if (parsedLine.complete) {
//                         return parsedLine.results;
//                     }
//                 } catch (error) {
//                     console.error("Error parsing progress update:", error);
//                 }
//             }
//         }
//     }
// }