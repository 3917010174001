import * as XLSX from "xlsx";

/**
 * Parses an Excel file and returns its contents as an object.
 *
 * @param {File} file - The Excel file to parse.
 * @returns {Promise<Object>} A promise that resolves to an object containing the parsed data.
 */


export const parseExcelFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // When the file is loaded, parse it and resolve the promise with the parsed data
    reader.onload = async (event) => {
      const data = new Uint8Array(event.target.result);

      const workbook = XLSX.read(data, { type: "array", cellDates: true });
      const sheetNames = workbook.SheetNames;

      const parsedData = {};

      // Check if there are any sheets in the workbook
      if (sheetNames.length === 0) {
        reject(new Error("Workbook does not contain any sheets"));
        return;
      }

      sheetNames.forEach((name) => {
        const worksheet = workbook.Sheets[name];
        const headers = {};
        const rows = [];

        // Loop through cells in worksheet
        Object.keys(worksheet).forEach((cell) => {
          // Skip metadata cells
          if (cell[0] === "!" || !worksheet.hasOwnProperty(cell)) {
            return;
          }

          // Get column and row values from cell
          const col = cell.match(/[A-Za-z]+/)[0];
          const row = parseInt(cell.match(/\d+/)[0]);

          // Store headers in object
          if (row === 1) {
            const value = worksheet[cell].v;
            headers[col] = value;
            return;
          }

          // Parse data rows
          if (row > 1) {
            if (!rows[row]) {
              rows[row] = {};
            }



            const cellValue = worksheet[cell];

            rows[row][headers[col]] = cellValue.v
          }
        });

        // Remove undefined elements from rows array
        const filteredRows = rows.filter((el) => el !== null && el !== undefined);

        parsedData[name] = {
          headers,
          rows: filteredRows,
        };
      });

      resolve(parsedData);
    };

    // If there was an error reading the file, reject the promise with an error
    reader.onerror = (error) => {
      console.log(error);
      reject(new Error("Invalid Excel file"));
    };

    // Read the file as an array buffer
    reader.readAsArrayBuffer(file);
  });
};