const columns = [
  {
    name: "ID",
    grow: 0,
    selector: (row) => {
      return (
        <a
          className="text-decoration-none"
          href={"/elements?target=" + row.idsrQPID}
        >
          {row.idsrQPID}
        </a>
      );
    },
  },
  {
    name: "Country",
    selector: (row) => row.country,
    sortable: true,
    wrap: true,
    grow: 0.3,
  },
  {
    name: "Title",
    selector: (row) => {
      return (
        <>
          <div>{row.title}</div>
          <div>
            <span>
              <a
                href={row.linkToForm}
                rel="noreferrer"
                target="_blank"
                className="text-decoration-none"
              >
                view form <i className="fa fa-external-link-alt" />
              </a>
            </span>
          </div>
        </>
      );
    },
    wrap: true,
    grow: 0.8,
  },
  {
    name: "Institution",
    selector: (row) => row.institution,
    wrap: true,
  },
  {
    name: "Type",
    selector: (row) =>
      row.qTypeMaps.map((item) => item.questionnaireType.type).join(", "),
    wrap: true,
  },
];

export default columns;
