/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import stores from "../store";
const { useSharedStore } = stores;

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  head: {
    style: {
      fontWeight: "bold", // Font weight for header
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
      fontWeight: "semibold", // make the headers bold
      fontSize: "12pt",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
    },
  },
  firstCell: {
    style: {
      width: "50px", // adjust the width of the first cell
      paddingLeft: "4px", // adjust the padding for the first cell
      paddingRight: "4px",
    },
  },
};

function ExpandedComponent({ data }) {
  return (
    <div>
      <p className="mt-4">
        {" "}
        Country:
        <span style={{ zoom: 0.9 }}>{data.country}</span>{" "}
      </p>
      <p className="">
        Description: <span style={{ zoom: 0.9 }}> {data.description} </span>
      </p>
      <p className="">
        {" "}
        Number of fields:{" "}
        <span style={{ zoom: 0.9 }}> {data.idsrElements.length} </span>
      </p>
      <p className="">
        <a href={data.linkToForm} target="_blank" rel="noreferrer">
        View original form
        </a>
      </p>
    </div>
  );
}

function QuestionnaireTable({
  data,
  columns,
  onIDClick,
  title,
  isQuestionnaireTable,
}) {
  const history = useHistory();

  // Retrieve the setSelectedQuestionnaire function from the Zustand store
  const setSelectedQuestionnaire = useSharedStore(
    (state) => state.setSelectedQuestionnaire
  );

  const modifiedColumns = columns.map((column) => {
    if (column.name === "ID" && onIDClick) {
      // If this is the "ID" column and an onIDClick handler is provided, modify the selector
      return {
        ...column,
        selector: (row) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            className="text-decoration-none"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onIDClick(row.idsrQPID); // Pass the ID to the handler
            }}
          >
            {row.idsrQPID}
          </a>
        ),
      };
    } else if (column.name === "Title" && setSelectedQuestionnaire) {
      // If this is the "Title" column and setSelectedQuestionnaire is available, modify the selector
      return {
        ...column,
        selector: (row) => (
          <div>
            <div
              onClick={() => {
                setSelectedQuestionnaire(row); // Pass the row to setSelectedQuestionnaire
              }}
              style={{ cursor: 'pointer' }}
            >
              {row.title}
            </div>
            <div>
              <span>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedQuestionnaire(row); // Pass the row to setSelectedQuestionnaire
                    history.push(`/elements?target=${row.idsrQPID}`);
                  }}
                  className="text-decoration-none"
                >
                  preview fields <i className="fa fa-external-link-alt" />
                </a>
              </span>
            </div>
          </div>
        ),
      };
    }
    return column;
  });

  return (
    <DataTable
      // title={title}
      columns={modifiedColumns}
      data={data}
      pagination
      responsive
      customStyles={customStyles}
      expandableRows={true}
      expandableRowsComponent={ExpandedComponent}
      highlightOnHover
      expandOnRowClicked
      pointerOnHover
    />
  );
}

export default QuestionnaireTable;
