import axios from 'axios';

async function fetchIdsrElements(id) {
  // Define the API endpoint
  const apiUrl = `https://us-central1-questmap-mubas.cloudfunctions.net/getIdsrElements?id=${id}`;

  try {
    const response = await axios.get(apiUrl);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
}

export { fetchIdsrElements };