import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import EditDescriptionModal from "./EditDescriptionModal";
import stores from "../../src/store";
import moveToRed from "../assets/images/arrow-right.png";
import edit from "../assets/images/edit.png";


const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
      fontWeight: "semibold", // make the headers bold
      fontSize: "12pt",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
    },
  },
  firstCell: {
    style: {
      width: "50px", // adjust the width of the first cell
      paddingLeft: "4px", // adjust the padding for the first cell
      paddingRight: "4px",
    },
  },
};

function SimulationScreenTable({
  editItemIndex,
  setEditItemIndex,
  editItemDescription,
  setEditItemDescription,
  selectedItems,
  setSelectedItems,
  data,
  filterValue,
  setIdsrElements,
  editedElements,
  setEditedElements,
  title,
}) {
  const { useFormStore } = stores;

  const {
    hasUserUploaded,
  } = useFormStore();

  async function handleEditDescription(item) {
    setEditItemIndex(item.index);
    setEditItemDescription(item.elemDescr);

    setShow(true);
  }

  const SimulationScreenColumns = [
    {
      name: "ID",
      selector: (row) => row.elemOrder,
      maxWidth: "0px",
      omit: true,
    },
    {
      minWidth: "30px",
      cell: () => (
        <div style={{ display: "inline-flex" }}>
          <div
            style={{
              ...colorColumnStyle,
              backgroundColor:
                filterValue === "GREEN"
                  ? "#10c23f"
                  : filterValue === "ORANGE"
                  ? "#f79e05"
                  : filterValue === "RED"
                  ? "red"
                  : "",
              marginRight: "0px",
            }}
          ></div>
     
        </div>
      ),
    },
    {
      name: "ID",
      selector: (row) => row.count,
      maxWidth: "50px",
    },

    {
      name: "Element Name",
      selector: (row) => row.elemName,
      minWidth: "250px",
      cell: (row) => (
        <div style={{ whiteSpace: "normal", padding: "5px" , width:'150px'}}>
          {row.elemName}
        </div>
      ),
    },
     {
      name: "Description",
      minWidth: "350px",
      allowOverflow: true,
      cell: (row) => (
        <div style={{ whiteSpace: "normal", display:'inline-flex' ,height:'20px'}}>
          <a  onClick={() => handleEditDescription(row)}
        style={{ color: "red", cursor: "pointer" }}>
        <img
          src={edit}
          color="red"
          height="25px"
          alt="Search Icon"
          className="search-icon"
          style={{ marginBottom: "0px", color: 'red', marginRight: '10px' }}
        />
      </a>

          <p style={{ display: "", marginRight: "0px",width:'200px' }}>{row.elemDescr}</p>
        </div>
      ),
    },

    {
      name: "Form",
      selector: (row) => row.idsrQListing.title,
      allowOverflow: true,
      cell: (row) => (
        <div style={{ whiteSpace: "normal" }}>{row.idsrQListing.title}</div>
      ),
      minWidth: "150px",
    },
    {
      minWidth: "50px",
      allowOverflow: true,
      cell: (row) => 
        row.color === "ORANGE" ? (
          <div style={{ whiteSpace: "normal" }}>
            <a  onClick={() => handleMoveToRed(row, data)}
              style={{ color: "red", cursor: "pointer" }}>
              <img
                src={moveToRed}
                color="red"
                height="40px"
                alt="Search Icon"
                className="search-icon"
                style={{ marginBottom: "0px", color: 'red', marginLeft: '40px' }}
              />
            </a>
          </div>
        ) : null,
    }
  ];

  const userUploadColumns = [
    {
      name: "ID",
      selector: (row) => row.elementOrder,
      allowOverflow: true,
      maxWidth: "50px",
      omit: true,
    },
    {
      name: "ID",
      allowOverflow: true,
      selector: (row) => row.elementOrder,
      maxWidth: "50px",
    },
    {
      name: "Name",
      allowOverflow: true,
      selector: (row) => row.elementName,
      maxWidth: "50px",
    },
    {
      name: "Description",
      minWidth: "350px",
      allowOverflow: true,
      cell: (row) => (
        <div style={{ whiteSpace: "normal", display:'inline-flex' ,height:'20px'}}>
          <a  onClick={() => handleEditDescription(row)}
        style={{ color: "red", cursor: "pointer" }}>
        <img
          src={edit}
          color="red"
          height="25px"
          alt="Search Icon"
          className="search-icon"
          style={{ marginBottom: "0px", color: 'red', marginRight: '10px' }}
        />
      </a>

          <p style={{ display: "", marginRight: "0px",width:'200px' }}>{row.elemDescr}</p>
        </div>
      ),
    },
    {
      minWidth: "50px",
      allowOverflow: true,
      cell: (row) => 
        row.color === "ORANGE" ? (
          <div style={{ whiteSpace: "normal" }}>
            <a  onClick={() => handleMoveToRed(row, data)}
              style={{ color: "red", cursor: "pointer" }}>
              <img
                src={moveToRed}
                color="red"
                height="40px"
                alt="Search Icon"
                className="search-icon"
                style={{ marginBottom: "0px", color: 'red', marginLeft: '40px' }}
              />
            </a>
          </div>
        ) : null,
    }
  ];


  const textInputRef = useRef(null);

  const [show, setShow] = useState(false);

  {
    /*
  const columnsWithCustomStyle = columns.map((col, index) => {
   if (index === 0) {
      return {
        ...col,
        cell: (row) => <FirstCell row={row} />,
        attributes: {
          className: "firstCell",
        },
      };
    }
    return col;
  });*/
  }

  const rowStyle = {
    display: "flex",
    flexDirection: "row",
    border: "1px solid #ccc",
    padding: "10px",
    margin: "5px",
    backgroundColor: "fcfcfc",
    width: "1100px",
  };

  const colorColumnStyle = {
    width: "20px", // Fixed width for the colored column
    backgroundColor: "green", // Default color
    height: "20px",
    borderRadius: "50%",
    marginRight: "0px",
  };

  const columnStyle = {
    flex: "1", // Allow other columns to grow and take remaining horizontal space
    display: "flex",
    flexDirection: "column", // Make each column a column
    padding: "7px",
  };

  const h3Style = {
    fontSize: "15px",
    margin: "0",
  };

  function handleMoveToRed(row, data) {
    let updatedTableData = [...data];
    const targetIndexValue = row.index;

    let parentMap = updatedTableData.find(
      (map) => map.index === targetIndexValue
    );

    if (parentMap) {
      parentMap.color = "RED";
      
      setIdsrElements(updatedTableData);
    }

    /*  // Make a shallow copy of tableData to avoid modifying the state directly
    let  updatedTableData = [...tableData];
    const targetIndexValue = data.index;
    console.log('index', targetIndexValue);
  
    let redFound = false;
    console.log('whole data', updatedTableData);

    // Step 1: Find the parent map based on the 'index' property in the copied array
    let parentMap = updatedTableData.find((map) => map.index === targetIndexValue);

    for (const map of tableData) {

  
    if (map.color === "RED" && map.index === targetIndexValue) {
      redFound = true;
      console.log('found RED', map)
      // Case 1: If a 'RED' item with the same index exists, add the child to it
      map.child.push(child);

      if (parentMap) {
        // Step 2: Access the child property
        const childArray = parentMap.child;
        const emptyMap = childArray.length;
        
    
        // Step 3: Remove the map with the particular index in the copied array
        const indexToRemove = childArray.findIndex((childItem) => childItem.index === child.index);
        if (indexToRemove !== -1) {
          childArray.splice(indexToRemove, 1); // Remove the map at indexToRemove
        } 

  
      }
      break;
    }


  }

   if(redFound == false){

    {
      // Case 2: If no 'RED' item with the same index exists, create a new 'RED' item
      const childItem = child;
      childItem.index = generateId();
  
      const vector = {
        ...data,
        color: 'RED',
        child: [childItem],
      };
      if (parentMap) {
        // Step 2: Access the child property
        const childArray = parentMap.child;
    
        // Step 3: Remove the map with the particular index in the copied array
        const indexToRemove = childArray.findIndex((childItem) => childItem.index === child.index);
        if (indexToRemove !== -1) {
          childArray.splice(indexToRemove, 1); // Remove the map at indexToRemove
        }
  
      }
  
      updatedTableData.push(vector);
    }
  }*/

    // Set the modified copy as the new state
    setIdsrElements(updatedTableData);
  }


  function checkIndexMatch(firstArray, secondArray) {
    let matchCount = 0; // Initialize counter for matches
  
    firstArray.forEach(item => {
      const indexExists = secondArray.some(element => element.index === item.index);
      if(indexExists) {
        matchCount++; // Increment count if match is found
      }
    });
  
    // Return true if more than one match was found, false otherwise
    return matchCount === 0;
  }
  
  

  const ExpandedComponent = ({ data }) => {

    const CheckCell = ({ row,fullData, selectedItems, setSelectedItems }) => {
      const handleCheckboxChange = () => {


   const check = checkIndexMatch(fullData,selectedItems);
        
        if(check){
        setSelectedItems((prevSelectedItems) => {
         /* const hasKey = prevSelectedItems.some(
            (item) => item.index === row.index
          );*/

      
            return [...prevSelectedItems, row];
          
        });

      }else{

        setSelectedItems((prevSelectedItems) => {
               
          return prevSelectedItems.filter((item) => item.index !== row.index);
          
        });

      
        
        



      }

      
      

        //console.log(selectedItems);
      };

      const isChecked = selectedItems.some((item) => item.index === row.index);

      return (
        <div style={{ marginRight: "20px" }} className="firstCell">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
        </div>
      );
    };

    return (
      <div>
        {data.child.map((item) => (
          <div key={item.id} style={rowStyle}>
            <CheckCell
            fullData={data.child}

              row={item}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />

            <div style={columnStyle}>
              <p style={h3Style}>
                <strong>ID:</strong> {item.id}
                <p>
                  <strong>Score:</strong> {item.score}
                </p>
              </p>
            </div>

            <div style={columnStyle}>
              <p style={h3Style}>
                <strong>Field Name:</strong> {item.metadata.fieldName}
              </p>
            </div>

            <div style={columnStyle}>
              <p style={h3Style}>
                <strong>Field Description:</strong> {item.metadata.fieldDescr}
              </p>
            </div>

            <div style={columnStyle}>
              <p style={h3Style}>
                <strong>IDSR Field Name:</strong> {item.metadata.idsrFieldID}
              </p>
            </div>

            <div style={columnStyle}>
              <p style={h3Style}>
                <strong>Sample Value:</strong> {item.metadata.sampleVal}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  function addCounters(filteredData) {
    dataCopy = filteredData;

    for (let i = 0; i < dataCopy.length; i++) {
      const count = i + 1;
      dataCopy[i].count = count;
    }
    return dataCopy;
  }

  const filterGreen = data.filter((item) => item.color === "GREEN");
  const filterOrange = data.filter((item) => item.color === "ORANGE");
  const filterRed = data.filter((item) => item.color === "RED");

  const handleClose = () => {
    setEditedElements([]);
    setShow(false);
  };

  const handleSave = () => {
    function findAndAddToNewArray(arrayOfMaps, targetIndex, newArray) {
      for (const map of arrayOfMaps) {
        if (map.index === targetIndex) {
          console.log("hello i found it");
          newArray.push(map);
        }
      }
      return newArray;
    }

    console.log("index", editItemIndex);
    const inputValue = textInputRef.current.value;
    let updatedEditedElements = [...editedElements];

    for (let i = 0; i < data.length; i++) {
      const item = data[i];

      if (item.index === editItemIndex) {
        console.log("found", item);
        // Update the fieldDescr in the matched map
        item.elemDescr = inputValue; // Use item2.metadata.fieldDescr
        findAndAddToNewArray(data, editItemIndex, updatedEditedElements);

        break; // Exit the function after the first match is found
      }
    }
    setEditedElements(updatedEditedElements);

    setIdsrElements(data);
    setShow(false);
  };




  useEffect(() => {
    // Call the exportCSV function and update the state when needed
    // const newData = exportCSV(data);
    // setExportCSVData(newData);
    console.log('New selected items', selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    // Call the exportCSV function and update the state when needed
    // const newData = exportCSV(data);
    // setExportCSVData(newData);
  }, []);

  let dataCopy = data;
/*
  for (let i = 0; i < data.length; i++) {
    dataCopy[i].defaultExpanded = true;
  } */

  console.log("here is user has uploaded", hasUserUploaded);

  return (
    <>
      <EditDescriptionModal
        show={show}
        handleClose={handleClose}
        handleSave={handleSave}
        textInputRef={textInputRef}
        defaultValue={editItemDescription}
      />

      <DataTable
        noDataComponent="No Records Found"
        columns={hasUserUploaded ? userUploadColumns : SimulationScreenColumns}
        title={title}
        data={
          filterValue === "GREEN"
            ? addCounters(filterGreen)
            : filterValue === "ORANGE"
            ? addCounters(filterOrange)
            : filterValue === "RED"
            ? addCounters(filterRed)
            : ""
        }
        pagination
        responsive
        customStyles={customStyles}
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        expandableRowsComponentProps={{ orginalData: data }}
        expandableRowExpanded={(row) => row.defaultExpanded}
      />
    </>
  );
}

export default SimulationScreenTable;
