import React from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { Container, Accordion, Card, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { useHistory } from "react-router-dom";
import stores from "../../store";

// Define API URL in a configuration file or environment variable
const API_URL =
  "https://us-central1-questmap-mubas.cloudfunctions.net/getQuestionnaireElements";

// Styles
const containerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
};


const textCenterStyle = {
  textAlign: "center",
  color: "red", // Text color is now black
};

// Function to fetch data from the API
const fetchData = async (targetValue) => {
  try {
    const response = await axios.get(`${API_URL}?id=${targetValue}`);
    return response.data || { elements: [] }; // Ensure you always have an "elements" array
  } catch (error) {
    // Handle errors more gracefully, e.g., log the error or show a user-friendly message
    throw new Error("Error fetching data: " + error.message);
  }
};

const QuestionnaireDetails = () => {
  const { useSharedStore } = stores;
  const {
    selectedQuestionnaire,
    setSelectedQuestionnaire,
    setQuestionnaireMetaInfo,
  } = useSharedStore();
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);

  console.log(selectedQuestionnaire)

  const handleStandardizeClick = () => {
    setSelectedQuestionnaire(selectedQuestionnaire.idsrQPID);
    setQuestionnaireMetaInfo(selectedQuestionnaire);

    history.push(`/simulation`);
  };

  const targetValue = queryParams.get("target");

  // Use useQuery unconditionally
  const { isLoading, isError, data } = useQuery(
    ["questionnaireElement", targetValue],
    () => fetchData(targetValue),
    {
      refetchInterval: 60000,
      enabled: !!selectedQuestionnaire, // Enable the query only if selectedQuestionnaire is truthy
    }
  );

  if (!selectedQuestionnaire) {
    return (
      <div style={containerStyle}>
        <Container>
          <p style={textCenterStyle}>
            No questionnaire selected. Please go back and select a
            questionnaire.
          </p>
        </Container>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div style={containerStyle}>
        <Container>
          <LoadingSpinner caption="Loading Data..." />
        </Container>
      </div>
    );
  }

  if (isError) {
    return <p>Error fetching data.</p>;
  }

  const questionnaireElements = data.elements || [];

  return (
    <div className="elements-page">
      <MetaTags>
        <title>Home | QuestMap</title>
      </MetaTags>

      <section style={{ marginTop: "60px" }}>
        <React.Fragment>
          <Header selectedQuestionnaire={selectedQuestionnaire} />

          <Container className="mt-3">
            <div className="mb-4">
              <Button
                variant="primary"
                style={{
                  width: "80px",
                  height: "40px",
                  marginRight: "10px",
                }}
                onClick={() => history.push(`/questionnaires`)}
              >
                Back
              </Button>

              <Button variant="primary" onClick={handleStandardizeClick}>
                <i className="uil uil-play"></i> Standardize
              </Button>
            </div>

            <h3 className="mb-3"> Elements</h3>
            <Container>
              <Accordion>
                {questionnaireElements.map((element) => (
                  <QuestionnaireElement
                    key={element.elemID}
                    element={element}
                  />
                ))}
              </Accordion>
            </Container>
          </Container>
        </React.Fragment>
      </section>
    </div>
  );
};

const QuestionnaireElement = ({ element }) => {
  const elementStyle = {
    marginBottom: "20px",
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    boxShadow: "none",
    background: "none", // Remove background color
  };

  const headerStyle = {
    backgroundColor: "transparent", // Remove header background color
    borderBottom: "1px solid #e0e0e0",
    padding: "10px 15px",
  };

  const toggleStyle = {
    color: "black", // Text color is now black
    fontWeight: "bold",
    textDecoration: "none",
  };

  const optionCardStyle = {
    marginBottom: "10px",
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    boxShadow: "none",
    background: "none",
  };

  return (

    <Card style={elementStyle}>
      <Card.Header style={headerStyle}>
        <Accordion.Toggle
          as={Button}
          variant="link"
          eventKey={element.elemName}
          style={toggleStyle}
        >
          {element.elemOrder + ". "} {element.elemName}
        </Accordion.Toggle>
      </Card.Header>
      <Accordion.Collapse eventKey={element.elemName}>
        <Card.Body>
          <strong>{element.title}</strong>
          <p>Description: {element.elemDescr}</p>
          <p>Rationale: {element.rationale}</p>
          <p>Element Number: {element.elemOrder || "N/A"}</p>
          <p>Field Type: {element.fieldType || "N/A"}</p>
          <p>Standardized Field: {element.fieldName || "N/A"}</p>

          {/* {element.options.map((option) => (
              <Badge key={option.oID} variant="secondary" pill style={{
                color: "black",
                borderRadius: "6px",
                border: "1px solid black",
                padding: "5px 10px",
                marginRight: "5px",
                marginBottom: "4px",
                cursor: "pointer",
              }} className="mr-2">
                {option.OText}
              </Badge>
            ))} */}

          {element.options && element.options.length > 0 ? (
            <p>
              Options:{" "}
              <Accordion style={{ marginTop: "15px" }}>
                {element.options.map((option) => (
                  <Card key={option.oID} style={optionCardStyle}>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey={`option-${option.no}`}
                        style={toggleStyle}
                      >
                        {option && option.order ? `${element.elemOrder}.${option.order}. ` : ""} {option?.OText || "No options available"}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={`option-${option.no}`}>
                      <Card.Body>
                        {option.definition ? (
                          <>
                            <p>Definition: {option.definition}</p>
                            <p>Type: {option.type}</p>
                            <p>Community Definition: {option.commDefinition || "Not available"}</p>
                          </>
                        ) : (
                          <p>No definition available</p>
                        )}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </p>
          ) : (
            <p>No options available</p>
          )}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

function Header({ selectedQuestionnaire, onStandardizeClick }) {
  return (
    <header className="header" style={{ padding: "auto", width: "100%" }}>
      <div className="header-info container">
        <HeaderInfo
          selectedQuestionnaire={selectedQuestionnaire}
          onStandardizeClick={onStandardizeClick}
        />
      </div>
    </header>
  );
}

function HeaderInfo({ selectedQuestionnaire }) {
  if (!selectedQuestionnaire) return null;

  return (
    <React.Fragment>
      <h2 className="header-title" style={{ marginBottom: "10px" }}>
        {selectedQuestionnaire && selectedQuestionnaire.title}
      </h2>
      <p>Country: {selectedQuestionnaire.country}</p>
      <p>No. fields: {selectedQuestionnaire.idsrElements.length}</p>
      <p>
        Category:{" "}
        {selectedQuestionnaire.qTypeMaps.map((qTypeMap, index) => (
          <span key={qTypeMap.qTypeMapID}>
            {qTypeMap.questionnaireType.type}
            {index < selectedQuestionnaire.qTypeMaps.length - 1 ? ", " : ""}
          </span>
        ))}
      </p>
    </React.Fragment>
  );
}
export default QuestionnaireDetails;
