import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import QuestionnairesPage from "./pages/Questionnaire/Questionnaires";
import OmopPage from "./pages/Omop/Omop";
import StandardizedPage from "./pages/Standardized/Standardized";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import ContactPage from "./pages/Contact/Contact";
import UploadPage from "./pages/Upload/Upload";
import NotFoundPage from "./pages/404/NotFoundPage";
// import ElementsPage from "./pages/Elements/Elements";
import ConfirmUploadPage from "./pages/ConfirmUpload/ConfirmUpload";
import ElementsPage from "./pages/Elements/ElementsAccordionPage";
import { ToastContainer } from "react-toastify";
import SimulationScreen from "./pages/Simulation/SimulationScreen";
import "react-toastify/dist/ReactToastify.css";

import { QueryClientProvider, QueryClient } from "react-query";

const App = () => {

  const [isTourEnabled, setIsTourEnabled] = useState(false);

  const toggleTour = () => {
    setIsTourEnabled(!isTourEnabled);
  };

  // Create a client
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // Set a custom cache timeout (in milliseconds)
        cacheTime: 1500000, // 25 minutes/

        // Set a time threshold for when data is considered "stale" (in milliseconds)
        // staleTime: Infinity, // Data will never be considered stale
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <NavBar toggleTour={toggleTour} />
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => <UploadPage {...props} isTourEnabled={isTourEnabled} />}
          />
          <Route path="/omop" component={OmopPage} />
          <Route path="/questionnaires" component={QuestionnairesPage} />
          <Route path="/standardized-fields" component={StandardizedPage} />
          <Route path="/contact-us" component={ContactPage} />
          <Route path="/upload-data" component={UploadPage} />
          <Route path="/elements" component={ElementsPage} />
          <Route path="/confirm-upload" component={ConfirmUploadPage} />
          <Route path="/simulation" component={SimulationScreen} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
        <Footer />
      </BrowserRouter>
      <ToastContainer position="top-center" autoClose={5000} />
    </QueryClientProvider>
  );
};

export default App;
