import LoadingGif from "../../assets/images/loading-gif.gif";

const InitialSetup = () => {
    const containerStyle = {
        marginTop: "7%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "45vh",
    };

    return (
        <div className="search-placeholder" style={containerStyle}>
            <img
                src={LoadingGif}
                height="70px"
                alt="Search Icon"
                className="search-icon"
                style={{ marginBottom: "50px" }}
            />
            <h4 className="placeholder-text">Setting things up...</h4>
        </div>
    );
};

export default InitialSetup