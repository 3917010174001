import { React, useState } from "react";
import {
  Form,
  Container,
  Row,
  Col,
  Button,
  Accordion,
  Card,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { parseExcelFile } from "../../helpers/userUpload/parseExcel";
import { toast } from 'react-toastify';
import stores from "../../store";
import countries from '../../utils/countries';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';

const UploadPage = () => {
  const history = useHistory();
  const [introEnabled, setIntroEnabled] = useState(false);

  const toggleTour = () => {
    setIntroEnabled(!introEnabled)
  }


  const { useFormStore } = stores;
  const {
    updateFormMeta,
    addFormValues,
    setHasUserUploaded,
  } = useFormStore();
  const [uploadedFile, setUploadedFile] = useState(null);

  const handleFileInputChange = (event) => {
    // const file = event.target.files[0]; // Get the first file from the input
    // setUploadedFile(file); // Store the file in state

    const file = event.target.files[0];

    // Check if the uploaded file has the correct file type
    if (file && file.name.endsWith('.xlsx')) {
      setUploadedFile(file); // Store the file in state
    } else {
      setUploadedFile(null); // Clear the uploaded file if it's not a CSV file
      toast.error('Please upload a Excel file.');
    }
  };

  const stepOptions = [
    {
      element: '#title',
      intro: 'Enter the title of your questionnaire here.',
      position: 'bottom'
    },
    {
      element: '#description',
      intro: 'Describe your questionnaire here.',
      position: 'bottom'
    },
    {
      element: '#country',
      intro: 'Select the country for your questionnaire.',
      position: 'bottom'
    },
    {
      element: '#author',
      intro: 'Enter the author name for your questionnaire.',
      position: 'bottom'
    },
    {
      element: '#type',
      intro: `Select the type of your questionnaire.`,
      // Multiple diseases: For multiple diseases <br>
      // Case Based:	For case-specific information <br>
      // Laboratory Test:	For disease-related laboratory data <br>
      // Single Disease:	For single disease information <br>
      // Aggregate: For weekly/monthly aggregated data for diseases <br>
      // Post Immunisation: For post-immunization status for individual cases
      position: 'bottom'
    },
    {
      element: '#institution',
      intro: 'Enter the institution name for your questionnaire.',
      position: 'bottom'
    },
    {
      element: '#version',
      intro: 'Enter the version of your questionnaire.',
      position: 'bottom'
    },
    {
      element: '#issue-date',
      intro: 'Select the issue date of your questionnaire.',
      position: 'bottom'
    },
    {
      element: '#csv-file',
      intro: 'Upload the CSV file for your questionnaire.',
      position: 'bottom'
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  async function changeLabels(formData) {
    const newKeyMapping = { elementDescription: "elemDescr" };

    // Create a deep copy of formData to avoid modifying the original data
    const form = JSON.parse(JSON.stringify(formData));

    for (const data of form) {
      for (const oldKey in newKeyMapping) {
        if (data.hasOwnProperty(oldKey)) {
          data[newKeyMapping[oldKey]] = data[oldKey];
          delete data[oldKey];
        }
      }
    }

    return form;
  }

  // async function parseExcel(file) {
  //   const parsedFile = await parseExcelFile(file);
  //   const formValues = await parsedFile["Sheet1"]["rows"];
  //   const formattedFormValues = await changeLabels(formValues);
  //   console.log('Here are form values', formValues);
  //   return { formValues: formValues, formattedFormValues: formattedFormValues };
  // }


  async function parseExcel(file) {
    try {
      const parsedFile = await parseExcelFile(file);

      // Check if the parsedFile is empty
      if (!parsedFile || Object.keys(parsedFile).length === 0) {
        toast.error('The file is empty or has no sheets.');
        return { error: 'Empty file or no sheets' };
      }

      const sheetData = parsedFile["Sheet1"];

      if (!sheetData) {
        // toast.error('The file has no data in Sheet1.');
        return { error: 'No data in Sheet1' };
      }

      const headers = sheetData["headers"];
      console.log("Headers:", headers);

      // Check if the headers match the expected values
      const expectedHeaders = ['elementOrder', 'elementName', 'elementDescription', 'sampleValues'];
      const headerKeys = Object.values(headers);

      const hasExpectedHeaders = expectedHeaders.every(header => headerKeys.includes(header));

      if (!hasExpectedHeaders) {
        return { error: 'The file headers do not match the expected format.' };
      }

      const formValues = sheetData["rows"].slice(0);
      const formattedFormValues = await changeLabels(formValues);

      console.log('Here are form values', formValues);

      return { headers, formValues, formattedFormValues };
    } catch (error) {
      return { error: 'Error parsing Excel file' };
    }
  }


  const onSubmit = async (data) => {

    try {
      const inputData = await parseExcel(uploadedFile);

      console.log(inputData)

      if (inputData && inputData.error) {
        // Handle the error condition
        toast.error(`Error parsing Excel file: ${inputData.error}`);
        return;
      }

      console.log(inputData)

      // Extract the meta data and CSV file from the form data
      const metaData = {
        title: data.title,
        description: data.description,
        country: data.country,
        author: data.author,
        type: data.type,
        institution: data.institution,
        version: data.version,
        issueDate: data.issueDate,
        stagings: inputData.formValues
      };

      updateFormMeta(metaData);
      addFormValues(inputData.formattedFormValues);
      setHasUserUploaded(true);

      // Navigate to the "confirm-upload" route
      history.push("/confirm-upload");

    } catch (error) {
      // Handle other unexpected errors
      console.error("Unexpected error:", error);
      toast.error('An unexpected error occurred. Please try again.');
    }
  };

  const containerStyle = {
    marginTop: "7%",
  };

  const inputFileStyle = {
    border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "8px",
    width: "100%",
    marginBottom: "3px",
  };

  const divContainer = {
    margin: "auto",
    maxWidth: "450px",
  };

  return (
    <Container style={containerStyle}>
      <Steps
        enabled={introEnabled} // Enable the guided tour
        steps={stepOptions}
        initialStep={0}
        onExit={toggleTour}
      />
      <div className="justify-content-center" style={{ marginBottom: "30px" }}>
        <div mb={20} className="text-center">
          <h4 mt={10}>Submit your Questionnaire <i onClick={toggleTour} className="fa text-primary fa-question-circle "></i></h4>
        </div>
      </div>
      <Row className="justify-content-center">
        <div style={divContainer}>
          <SelectedFileName uploadedFile={uploadedFile} />
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group as={Row} controlId="formName" id="title">
              <Form.Label column sm={3}>
                Title:
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  placeholder="Questionnaire Title"
                  style={inputFileStyle}
                  {...register("title", { required: true })}
                />
                {errors.title && (
                  <span className="text-danger">Title is required</span>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formDescription" id="description">
              <Form.Label column sm={3}>
                Description:
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  placeholder="Description"
                  style={inputFileStyle}
                  {...register("description", { required: true })}
                />
                {errors.description && (
                  <span className="text-danger">Description is required</span>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formCountry" id="country">
              <Form.Label column sm={3}>
                Country:
              </Form.Label>
              <Col sm={9}>
                {/* <Form.Control
                  type="text"
                  placeholder="Country"
                  style={inputFileStyle}
                  {...register("country", { required: true })}
                /> */}
                <Form.Control
                  as="select"
                  style={inputFileStyle}
                  {...register("country", { required: true })}
                >
                  <option value="">Select Country</option>
                  {countries.map((country, index) => (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  ))}
                </Form.Control>
                {errors.country && (
                  <span className="text-danger">Country is required</span>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formAuthor" id="author">
              <Form.Label column sm={3}>
                Author:
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  placeholder="Author"
                  style={inputFileStyle}
                  {...register("author", { required: true })}
                />
                {errors.author && (
                  <span className="text-danger">Author is required</span>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formType" id="type">
              <Form.Label column sm={3}>
                Type:
              </Form.Label>
              {/* <Col sm={9}>
                <Form.Control
                  type="text"
                  placeholder="Type of Questionnaire"
                  style={inputFileStyle}
                  {...register("type", { required: true })}
                />
                {errors.type && (
                  <span className="text-danger">Type is required</span>
                )}
              </Col> */}
              <Col sm={9}>
                <Form.Control
                  as="select"
                  style={inputFileStyle}
                  {...register("type", { required: true })}
                >
                  <option value="">Select Type</option>
                  <option value="Case Based">Case Based</option>
                  <option value="Multiple diseases">Multiple diseases</option>
                  <option value="Single diseases">Single diseases</option>
                  <option value="Laboratory Test">Laboratory Test</option>
                  <option value="Post Immunisation"> Post Immunisation</option>
                </Form.Control>
                {errors.type && (
                  <span className="text-danger">Type is required</span>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formInstitution" id="institution">
              <Form.Label column sm={3}>
                Institution:
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  placeholder="Institution"
                  style={inputFileStyle}
                  {...register("institution", { required: true })}
                />
                {errors.institution && (
                  <span className="text-danger">Institution is required</span>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formVersion" id="version">
              <Form.Label column sm={3}>
                Version:
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  placeholder="Version"
                  style={inputFileStyle}
                  {...register("version", { required: true })}
                />
                {errors.version && (
                  <span className="text-danger">Version is required</span>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formIssueDate" id="issue-date">
              <Form.Label column sm={3}>
                Issue Date:
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  placeholder="Issue Date"
                  type="date"
                  style={inputFileStyle}
                  {...register("issueDate", { required: true })}
                />
                {errors.issueDate && (
                  <span className="text-danger">Issue Date is required</span>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formCSV" id="csv-file">
              <Form.Label column sm={3}>
                CSV File:
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="file"
                  accept=".xlsx"
                  style={{ ...inputFileStyle }}
                  {...register("csvFile", { required: true })}
                  onChange={handleFileInputChange} // Attach the file input change handler
                />
                {errors.csvFile && (
                  <span className="text-danger">CSV file is required</span>
                )}
              </Col>
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100 mt-2" disabled={uploadedFile === null}>
              Submit
            </Button>

          </Form>

          <Accordion className="mb-6">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <span>Instructions</span>
                <span className="ml-auto">
                  {/* <FontAwesomeIcon icon={faChevronDown} /> */}
                </span>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  {/* Explanation text goes here */}
                  1. Download our sample CSV file{" "}
                  <a href="https://docs.google.com/spreadsheets/d/1Z7yQciytZ-qDY-zYpOUxgqSTyQR8PUmO/export?format=xlsx">
                    here
                  </a>
                  <br />
                  2. Fill in your Questionnaire elements and upload <br />
                  3. Fill in your questionnaire information above <br />
                  4. Submit
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </Row>
    </Container >
  );
};

const SelectedFileName = ({ uploadedFile }) => {
  return (
    <div>
      {uploadedFile && (
        <p className="text-primary">Selected File: {uploadedFile.name}</p>
      )}
    </div>
  );
};


export default UploadPage;
