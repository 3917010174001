import React from "react";
import { Button, Col, Form } from "react-bootstrap";
import { Input, Row } from "reactstrap";

const SearchOptions = () => {
  const [selectedTag, setSelectedTag] = React.useState("");

  const handleTagChange = (event) => {
    setSelectedTag(event.target.value);
  };

  const handleSearch = () => {
    // search
  };
  const tagOptions = [
    "Demographics",
    "Facility",
    "Patient",
    "Clinical",
    "Lab Test Fields",
    "Lab Result",
    "Measurement",
    "Symptom",
    "Condition",
    "Contact Detail",
    "Date",
    "Location",
  ];

  return (
    <React.Fragment>
      <div className="job-list-header">
        <Form action="#">
          <Row className="g-2">
            <Col lg={3} md={6}>
              <div className="filler-job-form">
                {/* <i className="uil uil-briefcase-alt"></i> */}
                <Input
                  type="search"
                  className="form-control filter-job-input-box-option"
                  id="exampleFormControlInput1"
                  placeholder="Field Name... "
                />
              </div>
            </Col>
            <Col lg={3} md={6}>
              <div className="filler-job-form">
                <select
                  className="form-select"
                  value={selectedTag}
                  onChange={handleTagChange}
                >
                  <option value="">Select Tag</option>
                  {tagOptions.map((tag, index) => (
                    <option key={index} value={tag}>
                      {tag}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            <Col lg={3} md={6}>
              <Button
                onClick={() => handleSearch}
                className="btn btn-primary w-100"
              >
                <i className="uil uil-filter"></i> Search
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default SearchOptions;
