import React, { useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Instructions({handleSearch,questionnaireMeta }) {
    const [openInstructions, setOpenInstructions] = useState(true);

    const buttonStyleTransparent = {
        borderRadius: "0px",
        height: "40px",
        marginBottom: "10px",
        marginRight: "10px",
        color: "black",
        backgroundColor: "transparent",
        border: "1px solid black"
    };

    const buttonStyle = {
        borderRadius: "0px",
        height: "40px",
        marginBottom: "10px",
        marginRight: "10px",
    };

    return (
        <div style={{paddingTop: "20px"}}>
            <Button
                hidden
                variant="secondary"
                onClick={() => setOpenInstructions(!openInstructions)}
                style={buttonStyleTransparent}
            >
                {openInstructions ? 'Collapse Instructions' : 'View Instructions'}
            </Button>
                <Button
                onClick={handleSearch}
                disabled={!questionnaireMeta}
                style={buttonStyle}
            >
                    <i className="uil uil-play"></i> {" "}
               Standardize
            </Button>
            
            <Collapse in={openInstructions}>
                <div>
                    <p style={{ fontSize: "14px" }}>
                        <br />
                        <h3>Standardization</h3>
                        The Standardization Process (highlighted) involves identifying
                        corresponding standard fields in our database for each element
                        within the questionnaire. These standardized fields are consistent
                        across all questionnaires, ensuring comparability. Please note
                        that this process may take some time.
                        <br />
                        <br />
                        Your questionnaire elements are color-coded for clarity:
                        <strong><span style={{ color: 'green', fontSize: '17px' }}> Green</span></strong> indicates elements with exact or highly
                        accurate matches.
                        <strong> <span style={{ color: 'orange', fontSize: '17px' }}> Orange</span></strong> highlights elements with approximate
                        matches.
                        <br />
                        <br />
                        To improve the accuracy of standardization for your Orange
                        elements, you can modify their descriptions (by clicking the edit icon next to the description). After making the
                        necessary adjustments, simply click the "Update Standardization"
                        button.
                        <br /><br />
                        Use the <strong><span style={{ color: 'red', fontSize: '17px' }}>Red</span></strong> arrow to move elements for which you cannot find appropriate standardized fields in our database. Try to identify a matching by first improving the description of elements (by clicking the edit icon next to the description).
                    </p>
                </div>
            </Collapse>
        </div>
    );
}