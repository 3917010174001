export function LoadingSpinner(props) {
  return (
    <>
      <div className="d-flex align-items-center justify-content-center">
        <div
          style={{ width: "30px", height: "30px" }}
          className="spinner-border spinner-border-sm text-primary me-2"
          role="status"
        ></div>
        <div className="">{props.caption}</div>
      </div>
    </>
  );
}
